import { Eraser, Filter, PlusCircle, Search, UserSquare2 } from "lucide-react";
import { IconButton } from "./IconButton";
import { MouseEventHandler } from "react";
import { TooltipComponent } from "../../../components/tooltip/tooltip";
import { useTranslation } from "react-i18next";

interface SearchButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  type?: "submit" | "reset" | "button" | undefined;
}

interface FilterButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  type?: "submit" | "reset" | "button" | undefined;
}

interface PlusButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  type?: "submit" | "reset" | "button" | undefined;
  title?: string;
  subTitle?: string;
}

const SearchButton = ({ onClick, type }: SearchButtonProps) => {
  const { t } = useTranslation();
  return (
    <TooltipComponent message={t("find")}>
      <IconButton
        variant={"search"}
        size={"default"}
        onClick={onClick}
        type={type}
      >
        <Search />
      </IconButton>
    </TooltipComponent>
  );
};

const FilterButton = ({ onClick, type }: FilterButtonProps) => {
  const { t } = useTranslation();
  return (
    <TooltipComponent message={t("filter")}>
      <IconButton
        variant={"outline"}
        size={"default"}
        onClick={onClick}
        type={type}
      >
        <Filter color={"#878685"} fill={"#878685"} />
      </IconButton>
    </TooltipComponent>
  );
};

const CleanButton = ({ onClick, type }: FilterButtonProps) => {
  const { t } = useTranslation();
  return (
    <TooltipComponent message={t("clean")}>
      <IconButton
        variant={"outline"}
        size={"default"}
        onClick={onClick}
        type={type}
      >
        <Eraser color={"#878685"} />
      </IconButton>
    </TooltipComponent>
  );
};

const PlusButton = ({ onClick, type, subTitle, title }: PlusButtonProps) => {
  return (
    <IconButton
      variant={"plusButton"}
      size={"plusButton"}
      onClick={onClick}
      type={type}
    >
      <PlusCircle color={"#EA7526"} size={30} />
      <div className="flex flex-col items-start">
        <span className="text-gray-150 text-xs uppercase font-normal">
          {subTitle}
        </span>
        <span className="text-gray-900 text-sm font-bold">{title}</span>
      </div>
    </IconButton>
  );
};

const IdentificationBadgeButton = ({ onClick, type, subTitle, title }: PlusButtonProps) => {
  return (
    <IconButton
      variant={"plusButton"}
      size={"plusButton"}
      onClick={onClick}
      type={type}
    >
      <UserSquare2 color={"#EA7526"} size={30} />
      <div className="flex flex-col items-start">
        <span className="text-gray-150 text-xs uppercase font-normal">
          {subTitle}
        </span>
        <span className="text-gray-900 text-sm font-bold">{title}</span>
      </div>
    </IconButton>
  );
};

export { SearchButton, FilterButton, PlusButton, CleanButton, IdentificationBadgeButton };
