import React, { ChangeEvent, FocusEventHandler, CSSProperties } from 'react';
import { InputAdornment } from '@mui/material';
import { Person, Lock } from '@mui/icons-material';
import { InputStyled, StyleHelperText } from './styles';
import { useTranslation } from 'react-i18next';

interface TextInputProps {
  error?: boolean;
  helperText?: any;
  value?: any;
  id?: string;
  label?: string;
  placeholder?: any;
  fullWidth?: boolean;
  startIcon?: string;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  type?: React.InputHTMLAttributes<unknown>['type'];
  className?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  style?: CSSProperties | undefined;
  autocomplete?: boolean;
  onKeyPress?:any;
  maxLength?: number
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined
}

export default function InputText(props: TextInputProps) {
  const { t } = useTranslation();
  const switchIcon = () => {
    switch (props.startIcon) {
      case 'person':
        return <Person style={{ fill: '#ccc' }} />;
      case 'password':
        return <Lock style={{ fill: '#ccc' }} />;
      default:
        return '';
    }
  };

  const getLabel = () => {
    if (props.label) {
      return t(`${props.label}`)
    }
    return ''
  }

  return (
    <InputStyled
      style={props.style ? props.style : { minWidth: 238 }}
      error={props.error}
      helperText={props.helperText}
      value={props.value}
      id={props.id}
      placeholder={props.placeholder}
      name={props.name}
      onChange={props.onChange}
      disabled={props.disabled}
      type={props.type}
      label={getLabel()}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{switchIcon()}</InputAdornment>
        ),
        style: {
          borderRadius: 50,
        }
      }}
      onBlur={props.onBlur}
      fullWidth={props.fullWidth}
      autoComplete={props.autocomplete ? 'on' : 'off'}
      FormHelperTextProps={{ style: StyleHelperText.styleHelperText }}
      color='secondary'
      focused
      inputProps={{
        maxLength: props.maxLength
      }}
      onKeyDown={props.onKeyDown}
    />
  );
}
