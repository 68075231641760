import React, { useEffect, useState } from 'react';
import Login from './Login';
import './LoginScreen.css';
import background from '../../assets/images/login/BK_LOGIN.png'
import { useParams, useHistory } from 'react-router-dom';
import { STORAGE_CURRENT_LOGGED_USER, STORAGE_TOKEN, saveStorage, saveStringStorage } from '../../services/storage';
import { getFranchiseById } from '../../services/authServices';
import { User } from '../../interfaces/profiles';
import { RoutesConstants } from '../../routes/routesConstants';
import { AlertComponent } from '../../components/alert/alert';

const LoginScreen: React.FC = () => {
  const [alertOpen, setAlertOpen] = useState(false)
  const { token, idUser }: any = useParams();
  const { push } = useHistory();

  async function TokenLogin() {
    if (token && idUser) {
      saveStringStorage(STORAGE_TOKEN, token);
      getFranchiseById(idUser)
        .then((res: any) => {
          const response: User = {
            RoleId: String(res.IdUserType),
            Email: res.Email,
            Token: token,
            UserName: res.Name,
            UserId: res.IdUser,
            Expires: ''
          };

          saveStorage(STORAGE_CURRENT_LOGGED_USER, response);
          push(RoutesConstants.home);
        })
        .catch(e => {
          setAlertOpen(true)
          console.log(e)
        })
    }
  }

  useEffect(() => {
    TokenLogin();
  }, [token, idUser])

  return (
    <div className='logon-container' >
      <div className='image-style sm:hidden xs:hidden md:flex'>
        <AlertComponent
          key={'LoginAlert'}
          message='Necessário validação do login'
          severity='info'
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
        />
        <img src={background} alt='Background' className='w-full h-full object-cover' />
      </div>
      <div className='form-login'>
        <Login />
      </div>
    </div>
  );
}

export default LoginScreen;
