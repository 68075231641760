import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { Avatar, AvatarFallback, AvatarImage } from "../avatar";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarTrigger,
} from "../menubar";
import { FileSearch, MoreHorizontal } from "lucide-react";
import { Link } from "react-router-dom";
import DataTablePagination from "./Pagination";
import { useState } from "react";
import { STORAGE_TOKEN, getStringStorage } from "../../../services/storage";
import { formatCpf } from "../../../components/utils/format";
var CryptoJS = require("crypto-js");

interface OptionsSelect {
  personID: number;
  name: string;
  cpfCnpj: string;
  birthdate: string;
  modifiedDate: string;
  status: number;
  lastAccess: string;
  profilePhoto: string;
}
interface PropsSelect {
  options: OptionsSelect[];
}
const dateString = (data: string) => {
  var dia = data.split("-")[2];
  var mes = data.split("-")[1];
  var ano = data.split("-")[0];

  if (ano === "string") {
    return "";
  } else {
    return dia + "/" + ("0" + mes).slice(-2) + "/" + ("0" + ano).slice(-4);
  }
};
const datePt = (date: Date) => {
  return date.toLocaleString;
};

export function TableComponent(props: PropsSelect) {
  const { t } = useTranslation();
  const secretKey = getStringStorage(STORAGE_TOKEN);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = props.options.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div>
      <Table>
        {currentItems.length === 0 ? (
          <div className="flex flex-col gap-5 items-center justify-center w-ful py-10">
            <FileSearch className="h-24 w-24" color="gray" />
            <span className="text-gray-150 text-lg">
              Nenhum registro encontrado
            </span>
          </div>
        ) : (
          <>
            <TableHeader>
              <TableRow>
                <TableHead>{t("nameID")}</TableHead>
                <TableHead>{t("birthdate")}</TableHead>
                <TableHead>{t("lastVisit")}</TableHead>
                <TableHead className="text-center">{t("actions")}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {currentItems?.map((patient: OptionsSelect, index: number) => {
                const encryptedUserId = CryptoJS.AES.encrypt(
                  patient.personID.toString(),
                  secretKey
                ).toString();

                return (
                  <TableRow
                    className="items-center justify-cente"
                    key={patient.personID}
                  >
                    <TableCell className="font-medium flex items-center gap-2">
                      <Avatar className="h-12 w-12 border">
                        <AvatarImage src={patient.profilePhoto} alt="@avatar" />
                        <AvatarFallback>WY</AvatarFallback>
                      </Avatar>
                      <div>
                        <tbody className="font-semibold">{patient.name}</tbody>
                        <tbody>{formatCpf(patient.cpfCnpj)}</tbody>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        <tbody className="font-semibold">
                          {dateString(patient.birthdate.toString())}
                        </tbody>
                        <tbody>{/* {patient.age}  */}</tbody>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        <tbody className="font-semibold">
                          {patient.lastAccess}
                        </tbody>
                        <tbody>
                          {/* {patient.lastAppointmentInMounths} */}
                        </tbody>
                        <tbody>{/* {patient.lastAppointmentDoctor} */}</tbody>
                      </div>
                    </TableCell>
                    <TableCell className="text-right items-center justify-center flex">
                      <Menubar className="border-none">
                        <MenubarMenu>
                          <MenubarTrigger>
                            <MoreHorizontal size={15} />
                          </MenubarTrigger>
                          <MenubarContent className="bg-white">
                            <MenubarItem>{t("medicalRecord")}</MenubarItem>
                            <MenubarSeparator />
                            <MenubarItem>
                              <Link
                                to={{
                                  pathname: `/patient/register`,
                                  state: { id: patient.personID }
                                }}
                              >
                                {t("registration")}
                              </Link>
                            </MenubarItem>
                          </MenubarContent>
                        </MenubarMenu>
                      </Menubar>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </>
        )}
      </Table>
      <DataTablePagination
        itemsPerPage={itemsPerPage}
        totalItems={props.options.length}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        setNumberOfItems={(size) => setItemsPerPage(size)}
      />
    </div>
  );
}
