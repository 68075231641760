import { Menubar } from "../../../components/ui/menubar";
import { useData } from "./MenuList";
import { asideStateStore } from "../../../store/zustand/asideState";
import { MenuItem } from "./NavigationMenuItem";
import {
  STORAGE_CURRENT_LOGGED_USER,
  getStorage,
} from "../../../services/storage";

export function LateralMenu() {
  const user = getStorage(STORAGE_CURRENT_LOGGED_USER);
  const { isOpen } = asideStateStore();

  const { menuData } = useData();

  return !user.token ? (
    <></>
  ) : (
    <aside
      className={`ml-0 h-svh absolute flex flex-col bg-white transition-all ease-in-out duration-300 z-10 ${
        isOpen ? "w-16" : "w-full"
      } max-w-44 min-w-16`}
    >
      <Menubar className="fixed flex-col gap-2 h-screen bg-white border-none py-4 text-white items-start mt-14">
        <MenuItem btn={menuData} isHide={isOpen} />
      </Menubar>
    </aside>
  );
}
