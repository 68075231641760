import { useTranslation } from "react-i18next";
import { OptionsCard } from "../../components/custom/OptionsCard/Card";
import { WelcomeHeader } from "../../components/custom/WelcomeHeader/WelcomeHeader";
import {
  STORAGE_CURRENT_LOGGED_USER,
  getStorage,
} from "../../services/storage";
import { UserProps } from "../../services/authServices";

export default function HomePage() {
  const user: UserProps = getStorage(STORAGE_CURRENT_LOGGED_USER);
  const { t } = useTranslation();

  return (
    <section className="relative w-full h-screen py-3 justify-center max-w-[1281px] pt-7 md:ml-16 sm:ml-16 xs:ml-16">
      <WelcomeHeader
        welcomeText={t("welcome")}
        name={user?.userName?.split("@")[0]}
        date={new Date()}
      />
      <div className="relative w-full gap-2 grid grid-cols-2">
        <OptionsCard
          btns={[
            {
              btnSubTitle: t("search"),
              btnTitle: t("patientSearch"),
              href: "/patients/patients-list",
              childrenName: "t.feature.patients", //provisorio
            },
            {
              btnSubTitle: t("registration"),
              btnTitle: t("patientRegistration"),
              href: "/patient/register",
              childrenName: "t.feature.patients-register",
            },
          ]}
          titleCard={t("patients")}
          childrenName="t.feature.patients"
        />
      </div>
    </section>
  );
}
