export const saveStorage = (key: string, value: object) =>
  localStorage.setItem(key, JSON.stringify(value));

export const saveStringStorage = (key: string, value: string) =>
  localStorage.setItem(key, value);

export const getStorage = (key: string) => {
  return JSON.parse(localStorage.getItem(key) || '');
};

export const getStringStorage = (key: string) => {
  return localStorage.getItem(key) || '';
};

export const clearStorage = () => {
  return localStorage.clear();
};
