import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

interface Data {
  value: number;
  label: string;
}

interface DropdownProps {
  options: Data[] | any;
  label: string;
  onChange: (onChangevalue?: number) => void;
  onInputChange?: () => void;
  value?: number | string | undefined;
  error?: boolean;
}

export default function Dropdown({
  options,
  label,
  onChange,
  onInputChange,
  value,
  error,
}: DropdownProps) {
  const onChangeFunction = (onChangevalue: Data | null) => {
    return onChange(onChangevalue?.value);
  };

  const objetoEncontrado = options.find(
    (item: { value: number }) => item.value === Number(value)
  );

  return (
    <Autocomplete
      disablePortal
      id="combo-box"
      value={objetoEncontrado?.label || null}
      options={options}
      onChange={(_, onChangevalue) => onChangeFunction(onChangevalue)}
      onInputChange={onInputChange}
      renderInput={(params) => <TextField {...params} label={label} className="max-w-screen-md" />}
      size="small"
      className={
        error ? "border-red-400 border-b border-l border-r rounded-md" : ""
      }
    />
  );
}
