import { Redirect, Route, Switch } from 'react-router-dom'
import { RoutesConstants } from './routesConstants'
import PrivateRoute from './private-route'
import LoginScreen from '../modules/Login/LoginScreen'
import HomePage from '../modules/Homepage/HomePageScreen'
import SearchPatient from '../modules/SearchPatient/SearchPatient'
import NewPatient from '../modules/Patient/newPatient'
import { STORAGE_TOKEN, getStringStorage } from '../services/storage'
import { NotFound } from '../modules/404/NotFound'

const Routes = () => {
  const token = getStringStorage(STORAGE_TOKEN);

  return (
    <Switch>
      <Route exact path="/">
        {
          !token ?
            <Redirect to={RoutesConstants.login} />
            :
            <Redirect to={RoutesConstants.home} />
        }
      </Route>

      <PrivateRoute isPrivate={false} path={`${RoutesConstants.login}`} component={LoginScreen} />
      <PrivateRoute path={RoutesConstants.home} component={HomePage} />
      <PrivateRoute path={RoutesConstants.SearchPatient} component={SearchPatient} />
      <PrivateRoute path={RoutesConstants.NewPatient} component={NewPatient} />

      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  )
}

export default Routes;