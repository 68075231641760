import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { STORAGE_TOKEN, getStringStorage } from '../services/storage';

import detector from 'i18next-browser-languagedetector';
import ptbr from './ptbr.json';
import enus from './enus.json';
import eses from './eses.json';

const token = getStringStorage(STORAGE_TOKEN);

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  pt: {
    translation: ptbr,
  },
  en: {
    translation: enus,
  },
  es: {
    translation: eses,
  },
};

const DETECTION_OPTIONS = {
  order: token ? ['localStorage', 'navigator'] : ['navigator', 'localStorage'],
  caches: ['localStorage']
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: DETECTION_OPTIONS,
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    fallbackLng: 'pt'
  });

export default i18n;
