import React, { SetStateAction, useEffect, useState } from 'react';
import Flag from 'react-world-flags'
import { Input } from '../../../components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '../../../components/ui/popover';
import { Button } from '../../../components/ui/button';
import { Check, ChevronsUpDown } from 'lucide-react';
import { Command, CommandEmpty, CommandGroup, CommandItem } from '../../../components/ui/command';
import { cn } from '../../../lib/utils';
import { useTranslation } from 'react-i18next';
import { TCountryCode, getCountryData } from 'countries-list';
import { RemoveCountryCode, SepararString } from '../../../components/utils/countryCodeValidator';

interface InputProps {
    phoneNumber: string
    countryCodes?: { label: string; value: string; }[]

    //onChange: (e: { target: { value: SetStateAction<string>; }; }) => void
    value?: any;
    onChange?: any;
    setCode: (selectedValue: string) => void;
    error?: boolean
}

const PhoneNumberInput = ({ phoneNumber, onChange, setCode, value, error }: InputProps) => {
    const { t } = useTranslation()

    const [open, setOpen] = React.useState(false)
    const [usedCode, setValue] = React.useState("")

    const [userCode, setUserCode] = React.useState<string | null>()
    const [userPhonenumber, setUserPhonenumber] = React.useState<string>()

    const [countryCodes, setCountryCodes] = useState([{ label: "", value: "" }])

    useEffect(() => {
        const fetchCountryCodes = () => {
            // códigos para Espanha, Brasil e Estados Unidos
            const selectedCountries: TCountryCode[] = ['ES', 'BR', 'US'];

            const codes = selectedCountries.map((isoCode) => ({
                value: isoCode,
                label: `+${getCountryData(isoCode).phone}`,
            }));

            setCountryCodes(codes);
        };

        const dataTel = SepararString(value);
        setUserCode(dataTel?.countryCode)

        const dddAndPhoneNumber = RemoveCountryCode(value)
        setUserPhonenumber(dddAndPhoneNumber.phoneNumber)

        fetchCountryCodes();
    }, [value]);

    return (
        <div className="flex-col">
            <div className={`flex ${error ? "border-red-500 " : "border-gray-150"} max-w-screen-md placeholder:text-gray-150 bg-white`}>

                <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger asChild className={`rounded-r-none  ${error ? "border-red-500 " : "border-gray-150 "} placeholder:text-gray-150 bg-gray-100`}>
                        <Button
                            variant="outline"
                            role="combobox"
                            aria-expanded={open}
                            className="w-fit justify-between"
                        >
                            {
                                usedCode
                                    ?
                                    <Flag code={countryCodes.find((country) => country.label === usedCode.toUpperCase())?.value} height={24} width={26} />
                                    : userCode ?
                                        <Flag code={countryCodes.find((country) => country.label === `+${userCode?.slice(0, 2)}`)?.value} height={24} width={26} />
                                        : `${t("selectCountry")}`
                            }
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                    </PopoverTrigger>

                    <PopoverContent className="w-fit p-0 rounded-md bg-white">
                        <Command>
                            <CommandEmpty>No flags found.</CommandEmpty>
                            <CommandGroup>
                                {countryCodes.map((country) => (
                                    <CommandItem
                                        key={country.value}
                                        value={country.label}
                                        onSelect={(currentValue) => {
                                            setValue(currentValue === usedCode ? "" : currentValue)
                                            setCode(currentValue === usedCode ? "" : currentValue)
                                            setOpen(false)
                                        }}
                                        className='focus:bg-slate-500'
                                    >
                                        <Flag code={country.value} height={24} width={26} />
                                        <Check
                                            className={cn(
                                                "ml-2 h-4 w-4",
                                                usedCode === country.value ? "opacity-100" : "opacity-0"
                                            )}
                                        />
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        </Command>
                    </PopoverContent>
                </Popover>
                <div className='items-center justify-center flex border-gray-150 border-x-0 border px-2'>
                    <span className='text-sm self-center'>{
                        usedCode
                            ?
                            countryCodes.find((country) => country.label === usedCode.toUpperCase())?.label
                            : userCode ?
                                countryCodes.find((country) => country.label === `+${userCode?.slice(0, 2)}`)?.label
                                : ``
                    }</span>
                </div>
                <Input
                    type="tel"
                    value={userPhonenumber}
                    onChange={onChange}
                    placeholder={t("phone")!}
                    className={`${error ? "border-red-500 " : "border-gray-150 "} max-w-screen-md rounded-l-none border-l-0 placeholder:text-gray-150 bg-white`}
                />
            </div>
        </div>
    );
};

export default PhoneNumberInput;
