import { Redirect, Route, RouteProps } from "react-router-dom";
import React, { ReactNode } from "react";
import { STORAGE_CURRENT_LOGGED_USER, STORAGE_TOKEN, getStorage, getStringStorage } from "../services/storage";
import { RoutesConstants } from "./routesConstants";
import { TopMenu } from "../components/custom/TopMenu/TopMenu";
import { LateralMenu } from "../components/custom/LateralMenu/LateralMenu";
import { UserProps } from "../services/authServices";
import { NotFound } from "../modules/404/NotFound";

interface PrivateRouteProps extends RouteProps {
  component?: React.ComponentType<any>;
  children?: ReactNode;
  isPrivate?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  children,
  isPrivate = true,
  ...rest
}) => {
  const token = getStringStorage(STORAGE_TOKEN);
  // const user: UserProps = getStorage(STORAGE_CURRENT_LOGGED_USER);
  const render = Component
    ? (props: any) => <Component {...props} />
    : () => children;

  if (!Component && !children) {
    return <Redirect to={"/"} />;
  }

  // function checkRoute(route: string) {
  //   const sanitizedRoute = route.replace(/^\//, '');

  //   return user.items?.some(item =>
  //     item.lstFeatures?.some(feature => feature.route === sanitizedRoute)
  //   );
  // }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isPrivate) return render(props);
        if (!token) {
          return <Redirect to={RoutesConstants.login} />;
        }
        // if (!checkRoute(props.location.pathname)) {
        //   return <NotFound />
        // }

        return (
          <>
            <TopMenu />
            <LateralMenu />
            <main className="px-4 relative justify-center flex bg-gray-50">
              {render(props)}
            </main>
          </>
        );
      }}
    />
  );
};

export default PrivateRoute;
