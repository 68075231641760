import { takeLatest } from 'redux-saga/effects';
import { fetchDataSaga } from './sagas/login-saga';
import { loginRequestAction, patientAutorizationAction } from './reducers';
import { fetchPatientSaga } from './sagas/patient-saga';

function* rootSaga() {
    yield takeLatest(loginRequestAction, fetchDataSaga);
    yield takeLatest(patientAutorizationAction, fetchPatientSaga);
}

export default rootSaga;