import { DatePicker, enUS, esES, ptBR } from "@mui/x-date-pickers";
import { Input, InputProps } from "../../../components/ui/input";
import "./style.css";
import { Dayjs } from "dayjs";
import i18n from "../../../translate/i18n";
import React from "react";

interface Props extends InputProps {
  typeInput: "text" | "date";
  label?: string;
  placeholder?: string;
  mask?: string | null;
  onClick?: () => void;
  dateValue?: Dayjs | null;
  inputValue?: string;
  onChange?: (e: any) => void;
  onChangeDate?: (value?: Dayjs | null) => void;
  maxDate?: Dayjs;
  error?: boolean
}

export const InputTextForm = ({
  label,
  placeholder,
  typeInput,
  mask,
  inputValue,
  dateValue,
  maxDate,
  onChange,
  onChangeDate,
  error
}: Props) => {
  const [locale, setLocale] = React.useState(ptBR)
  const [format, setFormat] = React.useState('DD/MM/YYYY')

  const setLanguage = () => {
    const lang = i18n.language
    switch (lang) {
      case "pt":
        setFormat('DD/MM/YYYY')
        setLocale(ptBR)
        break;
      case "en":
        setFormat('MM/DD/YYYY')
        setLocale(enUS)
        break;
      case "es":
        setFormat('DD/MM/YYYY')
        setLocale(esES)
        break;
      default:
        return
    }
  }

  React.useEffect(() => {
    setLanguage()
  }, [i18n.language])

  if (typeInput === "date") {
    return (
      <DatePicker
        label={label}
        value={dateValue}
        onChange={onChangeDate}
        format={format}
        // maxDate={maxDate}
        localeText={locale.components.MuiLocalizationProvider.defaultProps.localeText}
        disableFuture
        slotProps={{ textField: { size: 'small' } }}
      />
    );
  } else {
    return (
      <div className="mt-5">
        <p className="font-arial ml-1 font-normal text-xs text-slate-600">
          {""}
          {label}
        </p>
        <Input
          type="text"
          placeholder={placeholder}
          className={["inputTextForm"].join(" ")}
          value={inputValue}
          onChange={onChange}
        />
      </div>
    );
  }
};
