import { TextField } from '@mui/material';
import { styled } from '@mui/system';


export const InputStyled = styled(TextField)`
  font-family: Corbel, sans-serif;
  background: #ffffff;
  margin: 20px 0 15px;
  border-radius: 30px;
`;

export const StyleHelperText = {
  styleHelperText: {
    marginTop: '14px',
    fontSize: '12px'
  }
};