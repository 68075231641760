import axios from 'axios';
import { STORAGE_TOKEN, clearStorage, getStringStorage } from './storage';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const api = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  },
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const apiMultipart = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'multipart/form-data'
  },
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use(config=> {
  const token = getStringStorage(STORAGE_TOKEN)
  if(token){
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error.message);
    if (error.response) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        clearStorage();
        history.push('/');
        history.go(0);
      }
      if (error.response?.status === 500) {
        console.log(error.response.data.error);
      }
    }
    return Promise.reject(error);
  }
);

apiMultipart.interceptors.request.use(config=> {
  const token = getStringStorage(STORAGE_TOKEN)
  if(token){
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

apiMultipart.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error.message);
    if (error.response) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        clearStorage();
        history.push('/');
        history.go(0);
      }
      if (error.response?.status === 500) {
        console.log(error.response.data.error);
      }
    }
    return Promise.reject(error);
  }
);


export { api, apiMultipart };