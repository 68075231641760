import { format } from 'date-fns';

interface WelcomeHeaderProps {
  welcomeText: string;
  name?: string;
  date: string | number | Date;
}

export function WelcomeHeader({ name, date, welcomeText }: WelcomeHeaderProps) {
  return (
    <section className="my-10 flex flex-col">
      <span className="text-2xl font-bold mb-4 capitalize">
        {welcomeText}, {name}
      </span>
      <span className="text-sm font-normal text-primary-500 text-opacity-50">
        {format(date, 'dd/MM/yyyy')}
      </span>
    </section>
  );
}
