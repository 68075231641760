import { useTranslation } from "react-i18next";
import {
  CleanButton,
  FilterButton,
  PlusButton,
  SearchButton,
} from "../../components/custom/IconButton/Variants";
import { TableComponent } from "../../components/ui/Table/TableComponent";
import { useEffect, useState } from "react";
import { Button } from "../../components/ui/button";
import { RadioGroup, RadioGroupItem } from "../../components/ui/radio-group";
import { Label } from "../../components/ui/label";
import { TCountryCode, getCountryData } from "countries-list";
import React from "react";
import { GetListPerson } from "../../services/PatientConfirmation/patientAutorization";
import { useHistory } from "react-router-dom";
import { Loader } from "../../components/custom/Loader/Loader";
import { TextField } from "@mui/material";
import { InputTextForm } from "../../components/custom/InputTextForm";
import dayjs from "dayjs";

interface formState {
  personID: string;
  name: string;
  cpfCnpj: string;
  birthdate: any;
  email: string;
  mobilePhone: string;
  modified: string;
  profilePhoto: string;
  lastAccess: string;
  rg: string;
  status: number;
}

const filter = {
  personID: "",
  name: "",
  cpfCnpj: "",
  birthdate: "",
  modified: "",
  email: "",
  mobilePhone: "",
  rg: "",
  profilePhoto: "",
  lastAccess: "",
  status: 1,
};


export default function SearchPatient() {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [shownFilter, setShownFilter] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countyCodes, setCountryCodes] = useState([{ label: "", value: "" }]);


  const [rowsData, setRowsData] = React.useState<any>([]);

  const [search, setSearch] = React.useState<formState>(filter);

  const handleClean = () => {
    setSearch({
      ...search,
      personID: "",
      name: "",
      cpfCnpj: "",
      lastAccess: "",
      birthdate: "",
      email: "",
      rg: "",
      mobilePhone: "",
      status: 1,
    });
  };

  const handleSearch = React.useCallback(async (search: any) => {
    setIsLoading(true);
    await GetListPerson(search)
      .then((res: any) => {
        let data: {
          personID: number;
          name: string;
          cpfCnpj: string;
          lastAccess: string;
          birthdate: string;
          email: string;
          status: any;
          modifiedDate: string;
          mobilePhone: string;
          rg: string;
          profilePhoto: string;
        }[] = [];

        res.person.map((item: any) => {
          const list = {
            personID: item.personID,
            name: item.name,
            cpfCnpj: item.cpfCnpj,
            lastAccess: item.lastAccess,
            birthdate: item.birthdate,
            email: item.email,
            rg: item.rg,
            status: item.status,
            mobilePhone: item.mobilePhone,
            modifiedDate: item.modifiedDate,
            profilePhoto: item.profilePhoto,
          };
          data.push(list);
          return "";
        });
        data.sort((a, b) =>
          a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
        );
        const updatedJson = Object.assign({}, search, { ...search });
        setRowsData(data);
        GetListPerson(updatedJson);
        setIsLoading(false);
      })
      .catch(() => {
        setRowsData([]);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    handleSearch(search);
  }, [handleSearch, search]);

  useEffect(() => {
    const fetchCountryCodes = () => {
      // códigos para Espanha, Brasil e Estados Unidos
      const selectedCountries: TCountryCode[] = ["ES", "BR", "US"];

      const codes = selectedCountries.map((isoCode) => ({
        value: isoCode,
        label: `+${getCountryData(isoCode).phone}`,
      }));

      setCountryCodes(codes);
    };

    fetchCountryCodes();
  }, []);

  return (
    <section className="relative w-full py-3 justify-center pt-16 md:ml-16 sm:ml-16 xs:ml-16 bg-gray-50">
      <div className="flex mb-5">
        <span>
          {t("patients")} / <b>{t("patientsSearch")}</b>
        </span>
      </div>
      {!shownFilter ? (
        <div className="flex w-full gap-2">
          <TextField
            className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
            label={t("searchByNameOrID")!}
            value={search?.name}
            onChange={(event) =>
              setSearch({ ...search, name: event.target.value })
            }
            size="small"
            sx={{ width: "100%" }}
          />
          <SearchButton onClick={() => handleSearch(search)} />
          <CleanButton onClick={() => handleClean()} />
          <FilterButton onClick={() => setShownFilter(!shownFilter)} />
          <div className="justify-end flex w-full">
            <PlusButton
              title={t("patient")!}
              subTitle={t("new")!}
              onClick={() => history.push("/patient/register")}
            />
          </div>
        </div>
      ) : (
        <div className="relative gap-2 grid grid-cols-2 mt-3 p-3 bg-white rounded-md border-t-2 shadow border-t-senario-700">
          <TextField
            size="small"
            className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
            label={t("name")!}
            value={search?.name}
            onChange={(event) =>
              setSearch({ ...search, name: event.target.value })
            }
          />
          <TextField
            size="small"
            className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
            label={t("ID")!}
            value={search?.cpfCnpj}
            onChange={(event) =>
              setSearch({ ...search, cpfCnpj: event.target.value })
            }
          />
          <TextField
            size="small"
            className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
            label={t("email")!}
            value={search?.email}
            onChange={(event) =>
              setSearch({ ...search, email: event.target.value })
            }
          />
          <TextField
            size="small"
            className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
            label={t("securityNumber")!}
            value={search?.rg}
            onChange={(event) =>
              setSearch({ ...search, rg: event.target.value })
            }
          />

          <TextField
            size="small"
            className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
            label={t("phone")}
            value={search?.mobilePhone}
            onChange={(event: { target: { value: any } }) =>
              setSearch({ ...search, mobilePhone: event.target.value })
            }
          />

          <div className="flex gap-4">
            <InputTextForm
              typeInput="date"
              label={t("birthdate")!}
              dateValue={search?.birthdate ? dayjs(search?.birthdate) : null}
              onChangeDate={(value) =>
                setSearch({ ...search, birthdate: value?.format('YYYY-MM-DD') })
              }
              maxDate={dayjs(new Date())}
            />

            <div className="gap-1 flex flex-col">
              <span className="text-sm -mt-1 flex text-gray-150 ml-4">
                {t("status")}
              </span>
              <RadioGroup defaultValue="ativo" className="flex gap-4">
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    value="ativo"
                    id="r1"
                    onClick={() => setSearch({ ...search, status: 1 })}
                  />
                  <Label htmlFor="r1">{t("active")}</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    value={"inativo"}
                    id="r2"
                    onClick={() => setSearch({ ...search, status: 2 })}
                  />
                  <Label htmlFor="r2">{t("inactive")}</Label>
                </div>
              </RadioGroup>
            </div>
          </div>

          <div />

          <div className="flex gap-2 justify-end">
            <Button
              variant="link"
              className="w-fit"
              onClick={() => setShownFilter(!shownFilter)}
            >
              {t("cancel")}
            </Button>
            <CleanButton onClick={() => handleClean()} />
            <SearchButton onClick={() => handleSearch(search)} />
          </div>
        </div>
      )}
      <div className="w-full flex flex-col">
        <TableComponent options={rowsData} />
      </div>
      <Loader isLoading={isLoading} />
    </section>
  );
}
