import { put, call } from 'redux-saga/effects';
import {  patientAutorizationFailure, patientAutorizationSuccess } from '../reducers';
import { SendNotificationAndEmail } from '../../services/PatientConfirmation/patientAutorization';

export function* fetchPatientSaga(action: any): Generator<any, void, any> {
  try {
    const data = yield call(SendNotificationAndEmail, action.payload);
    yield put(patientAutorizationSuccess(data));
  } catch (error: any) {
    yield put(patientAutorizationFailure(error.message));
  }
}