import { TFunction } from "i18next";

export function titularOptions(
  t: TFunction<"translation", undefined, "translation">
) {
  const opcoesTitular = [
    { value: 16, label: t("relationshipOwnPatient") },
    { value: 1, label: t("relationshipSon") },
    { value: 2, label: t("relationshipDaughter") },
    { value: 3, label: t("relationshipGrandson") },
    { value: 4, label: t("relationshipGranddaughter") },
    { value: 5, label: t("relationshipNephew") },
    { value: 6, label: t("relationshipNiece") },
    { value: 7, label: t("relationshipOtherGuardian") },
    { value: 8, label: t("relationshipFather") },
    { value: 9, label: t("relationshipMother") },
    { value: 10, label: t("relationshipGrandfather") },
    { value: 11, label: t("relationshipGrandmother") },
    { value: 12, label: t("relationshipUncle") },
    { value: 13, label: t("relationshipAunt") },
    { value: 14, label: t("relationshipBrother") },
    { value: 15, label: t("relationshipSister") },
  ];

  return opcoesTitular;
}
