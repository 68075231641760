export class ValidationsService {
  static EmailFieldValidation(email?: string): string {
    // eslint-disable-next-line
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email) {
      return 'fillFieldEmail';
    }
    if (!reg.test(email!)) {
      return 'invalidEmail';
    }

    return '';
  }

  static EmailValidation(email?: string): string {
    // eslint-disable-next-line
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email && !reg.test(email!)) {
      return 'invalidEmail';
    }

    return '';
  }

  static PasswordValidation(Password?: string, New?: boolean) {
    const regex = /^(?=.*[@!#$%^&*‘“_=;><?:()/\\])[@!#$%^&*‘“_=;><?:()/\\a-zA-Z0-9]{8,8}$/;
    if (!Password) {
      let erro = '';
      if (New) {
        erro = 'fillFieldNewPassword';
      } else {
        erro = 'fillFieldPassword';
      }

      return erro;
    }
    if (!regex.test(Password!)) {
      return 'passwordRequirements';
    }

    return undefined;
  }

  static ConfirmPasswordValidation(Password?: string, NewPassword?: string) {
    if (!NewPassword) {
      return 'fillFieldConfirmationNewPassword';
    }
    if (Password && Password !== NewPassword) {
      return 'newPasswordAndConfirmationNotMatch';
    }

    return undefined;
  }

  static isValidPhone(phone?: string) {
    const brazilianPhoneRegex = /^\(\d{2}\) \d{4,5}-\d{4}$/gi;

    if (!phone) {
      return 'fillFieldPhone';
    }
    let inputPhone = phone!.replace('(', '');
    inputPhone = inputPhone.replace(')', '');
    inputPhone = inputPhone.replace('-', '');
    inputPhone = inputPhone.trim();
    if (!inputPhone) {
      return 'fillFieldPhone';
    }

    if (!brazilianPhoneRegex.test(phone!)) {
      return 'invalidPhone';
    }
    return undefined;
  }

  static isValidZipCode(zipCode?: string) {
    const brazilianZipCode = /^\d{2}.\d{3}-\d{3}$/gi;

    let inputZipCode = zipCode!.replace('.', '');
    inputZipCode = inputZipCode.replace('-', '');
    inputZipCode = inputZipCode.trim();

    if (!inputZipCode) {
      return 'fillFieldZipCode';
    }

    if (!brazilianZipCode.test(zipCode!)) {
      return 'invalidZipCode';
    }
    return undefined;
  }

  static isValidRegistrationNumber(registrationNumber?: string) {
    const brazilianRegistrationNumber = /^\d{3}.\d{3}.\d{3}-\d{2}$/gi;

    let inputRegistrationNumber = registrationNumber!.replace('.', '');
    inputRegistrationNumber = inputRegistrationNumber.replace('.', '');
    inputRegistrationNumber = inputRegistrationNumber.replace('-', '');
    inputRegistrationNumber = inputRegistrationNumber.trim();

    if (!inputRegistrationNumber) {
      return 'fillFieldRegistrationNumber';
    }

    if (!brazilianRegistrationNumber.test(registrationNumber!)) {
      return 'invalidRegistrationNumber';
    }

    let soma = 0;
    let resto;
    let inputCPF = registrationNumber!.replace('-', '');
    inputCPF = inputCPF.replace('.', '');
    inputCPF = inputCPF.replace('.', '');

    soma =
      parseInt(inputCPF.substring(0, 1)) * 10 +
      parseInt(inputCPF.substring(1, 2)) * 9 +
      parseInt(inputCPF.substring(2, 3)) * 8 +
      parseInt(inputCPF.substring(3, 4)) * 7 +
      parseInt(inputCPF.substring(4, 5)) * 6 +
      parseInt(inputCPF.substring(5, 6)) * 5 +
      parseInt(inputCPF.substring(6, 7)) * 4 +
      parseInt(inputCPF.substring(7, 8)) * 3 +
      parseInt(inputCPF.substring(8, 9)) * 2;
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(inputCPF.substring(9, 10))) {
      return 'invalidRegistrationNumber';
    }

    soma = 0;
    soma =
      parseInt(inputCPF.substring(0, 1)) * 11 +
      parseInt(inputCPF.substring(1, 2)) * 10 +
      parseInt(inputCPF.substring(2, 3)) * 9 +
      parseInt(inputCPF.substring(3, 4)) * 8 +
      parseInt(inputCPF.substring(4, 5)) * 7 +
      parseInt(inputCPF.substring(5, 6)) * 6 +
      parseInt(inputCPF.substring(6, 7)) * 5 +
      parseInt(inputCPF.substring(7, 8)) * 4 +
      parseInt(inputCPF.substring(8, 9)) * 3 +
      parseInt(inputCPF.substring(9, 10)) * 2;
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(inputCPF.substring(10, 11))) {
      return 'invalidRegistrationNumber';
    }

    return undefined;
  }
}
