import { api } from "../api";
interface filterProps {
  personID: string;
  name: string;
  cpfCnpj: string;
  birthdate: string;
  status: string;
  email: string;
  rg: string;
  mobilePhone: string;
}
export function SendNotificationAndEmail(idSchedule: string) {
  return api.get(
    `/api/schedulemanager/getInfoConsultByIdSchedule?idSchedule=${idSchedule}`
  );
}

export function SendNotificationAndEmail1(idSchedule: string) {
  return api.get(
    `/api/schedulemanager/getInfoConsultByIdSchedule?idSchedule=${idSchedule}`
  );
}

export function GetPatientInformationStatus(
  idPatient: string,
  isApproved: boolean
) {
  return api.get(
    `/api/Patient/getpatientapproved?idPatient=${idPatient}&approved=${isApproved}`
  );
}

export function GetListPerson(props: filterProps) {
  function hasNumber(str: string) {
    const cleanedStr = str.replace(/[^a-zA-Z0-9]/g, "");
    return /\d/.test(cleanedStr);
  }

  var result = api.get(
    `/api/person/GetPerson?name=${
      hasNumber(props.name) ? "" : props.name
    }&email=${props.email}&cpfCnpj=${
      hasNumber(props.name) ? props.name : props.cpfCnpj
    }&birthdate=${props.birthdate}&status=${props.status}&phone=${
      props.mobilePhone
    }&rg=${props.rg}`
  );

  return result;
}
