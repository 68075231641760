import styled from 'styled-components';
import logo from '../../assets/images/login/wellny_logo.png';

export const LogoImage = styled.img.attrs({
  src: logo
})`
  padding: 30px 30px 30px 0;
  margin-bottom: 10px;
  margin-left: -2px;

  @media (max-width: 768px) {
    height: 70px;
  }

  @media (max-width: 300px) {
    height: 67px;
  }
`;

export const InsideBox = styled.div`
  height: 100%;
  border-left: solid #ea7526;
  margin-left: 50px;
  margin-right: 50px;

  @media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

export const LoginForm = styled.div`
  text-align: center;
  position: relative;
  padding: 30px 40px 15px 40px;
  margin-left: 15px;

  @media (max-width: 360px) {
    width: 230px;
  }

  @media (max-width: 325px) {
    width: 190px;
  }

  @media (max-width: 280px) {
    width: 150px;
  }
`;

export const TitleContainer = styled.div`
  text-align: left;
  position: relative;
  padding: 30px 40px 15px 0px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  font-family: Corbel, sans-serif;
  gap: 14px;
  align-items: left;
  width: 384px;

  @media (max-width: 360px) {
    width: 230px;
  }

  @media (max-width: 325px) {
    width: 190px;
  }

  @media (max-width: 280px) {
    width: 150px;
  }
`;

export const TitleLogin = styled.text`
  font-size: 23px;
  font-weight: bold;
`;

export const SubtitleLogin = styled.text`
  font-size: 16px;
  font-weight: 300;
  color: gray;
`;

export const InputStyle = {
  inputResponsive: {
    minWidth: '120px',
    width: '90%'
  }
};
