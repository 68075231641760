import React, { useState, useEffect } from "react";
import { ErrorOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { RoutesConstants } from "../../routes/routesConstants";
import {
  LoginForm,
  InputStyle,
  TitleContainer,
  TitleLogin,
  SubtitleLogin,
} from "./loginStyles";
import InputText from "../../components/custom/InputText/text";
import "./LoginScreen.css";
import { ValidationsService } from "../../services/validations/validations";
import { Button } from "../../components/custom/ButtonShadcn/Button";
import { useDispatch } from "react-redux";
import { LoginRequest } from "../../models/request";
import { useAppSelector } from "../../hooks/ReducerSelector";
import { loginRequestAction } from "../../store/reducers";
import ModalRecoveryPassword from "./ModalRecoveryPassword";
import { recoveryPasswordService } from "../../services/authServices";
import Logo from "../../assets/images/logo_color.png";
import { LockIcon, User2 } from "lucide-react";
import { Input } from "../../components/ui/input";
import { EyeClosedIcon, EyeOpenIcon } from "@radix-ui/react-icons";

export default function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useAppSelector((state) => state.auth);
  const { push } = useHistory();
  const [openModal, setOpenModal] = useState(false);

  const [typeInput, setTypeInput] = useState<"password" | "text">("password");

  const [inputs, setInputs] = useState<LoginRequest>({
    email: "",
    password: "",
    emailValidation: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));

    if (name === "email") {
      validateEmail(value);
    }
  };

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(loginRequestAction(inputs));
    dispatch(loginRequestAction(inputs));
  };

  function handleDisableLogin(): boolean {
    if (
      inputs.email.length > 0 &&
      inputs.password.length > 0 &&
      !inputs.emailValidation
    ) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (auth.data?.token) {
      push(RoutesConstants.home);
    }
  }, [push, auth.data]);

  function validateEmail(email: string) {
    const validation = ValidationsService.EmailValidation(email);

    setInputs((inputs) => ({
      ...inputs,
      emailValidation: t(validation),
    }));
  }

  const onRecoveryPassword = (email: any) => {
    recoveryPasswordService(email);
    setOpenModal(false);
  };

  return (
    <div className="w-full h-full justify-center items-center flex flex-col px-12">
      <TitleContainer>
        <img src={Logo} alt="Logo wellny" width={150} />
        <SubtitleLogin className="subtitle-login">
          {t("accessWithEmailAndPassword")}
        </SubtitleLogin>
      </TitleContainer>

      <form
        id="login-form"
        onSubmit={(e) => {
          handleLogin(e);
        }}
        className="gap-6 flex flex-col py-4 w-96"
      >
        <div className="relative">
          <User2 className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            id="txt-login-email"
            placeholder={t("email")!}
            name="email"
            type="text"
            onChange={handleChange}
            className="pl-8 rounded-full border-gray-400 border h-10"
          />
        </div>

        <div className="relative">
          <LockIcon className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            name="password"
            placeholder={t("password")!}
            type={typeInput}
            onChange={handleChange}
            className="pl-8 rounded-full border-gray-400 border h-10"
          />
          <button
            type="button"
            onClick={() =>
              setTypeInput(typeInput === "password" ? "text" : "password")
            }
          >
            {typeInput === "password" ? (
              <EyeOpenIcon className="absolute right-4 top-2.5 h-4 w-4 text-muted-foreground fill-primary-700" />
            ) : (
              <EyeClosedIcon className="absolute right-4 top-2.5 h-4 w-4 text-muted-foreground fill-primary-700" />
            )}
          </button>
        </div>

        <div className="flex flex-col items-start">
          <p>
            {auth.error && (
              <div className="my-4">
                <span className="bg-red-500 p-1 text-white rounded-md">
                  {t("loginError")}
                </span>
              </div>
            )}
          </p>
          <Button
            id="btn-login-signin"
            typeButton="buttonRight"
            label={t("login")!}
            type="submit"
            disabled={auth.loading || handleDisableLogin()}
          />

          <Button
            id="btn-login-forgotPassword"
            className="self-end mt-3 text-gray-600 text-sm"
            typeButton="link"
            type="button"
            label={t("forgotPassword")!}
            onClick={() => setOpenModal(!openModal)}
          />
        </div>
      </form>

      <ModalRecoveryPassword
        open={openModal}
        handleModal={() => setOpenModal(!openModal)}
        onAccept={onRecoveryPassword}
      />
    </div>
  );
}
