import { UserProps } from '../../services/authServices';
import { STORAGE_CURRENT_LOGGED_USER, getStorage, getStringStorage } from '../../services/storage';
import React, { ReactNode } from 'react';

interface Feature {
  featureID: number;
  featureGuid: string;
  name: string;
  route: string;
  featureParentID: number | null;
  sort: number;
  showMenu: number;
  active: number;
  isScreen: number;
}

interface Role {
  role: {
    active: string;
    description: string;
    id: string;
    name: string;
    normalizedName: string;
    concurrencyStamp: null | string;
  };
  lstFeatures: Feature[];
}

interface RolesData {
  items: Role[];
}

interface ParentComponentProps {
  childrenName: string;
  children: ReactNode;
  rolesData?: RolesData;
}

const ParentComponent: React.FC<ParentComponentProps> = ({
  childrenName,
  children
}) => {
  const roles: UserProps = getStorage(STORAGE_CURRENT_LOGGED_USER)
  const rolesData = roles.items;

  if (rolesData) {
    for (const role of rolesData as unknown as any) {
      const matchingFeature = role.lstFeatures.find(
        (feature: { name: string, showMenu: 1 | 0 }) => feature.name === childrenName && feature.showMenu === 1
      );

      if (matchingFeature) {
        return <>{children}</>;
      }
    }
  }

  return null;
};

export default ParentComponent;
