import { TFunction } from "i18next";
import i18n from "../../translate/i18n";

export function handleChangeLanguage(language: string) {
  if (language) {
    const lang = language.substring(0, 2);
    i18n.changeLanguage(lang);
  }
}

export function validarEmail(email: string) {
  // Expressão regular para validar o formato do email
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Testar se o email corresponde à expressão regular
  return regex.test(email);
}

export function validateRequiredPatientRegisterInput(personDataPost: any, t: TFunction<"translation", undefined, "translation">) {
  const requiredFields = [
    { id: "Name", value: t("name") },
    { id: "Email", value: t("email") },
    { id: "CpfCnpj", value: t("ID") },
    { id: "Birthdate", value: t("birthdate") },
    { id: "Street", value: t("street") },
    { id: "Zipcode", value: t("zipcode") },
    { id: "GeoUnitCountryID", value: t("country") },
    { id: "State", value: t("state") },
    { id: "CityName", value: t("city") },
    { id: "Number", value: t("number") },
    { id: "ProfessionName", value: t("profession") },
    { id: "RG", value: t("securityNumber") },
    { id: "Neighborhood", value: t("neighborhood") },
    { id: "TitularRg", value: t("securityNumberHolder") },
    { id: "TitularCpfCnpj", value: t("cpfHolder") },
    { id: "Phone", value: `${t("phone")} 1` },
    { id: "MobilePhone", value: `${t("phone")} 1` },
    { id: "genderTypeBiologicID", value: t("biologicalSex") },
    { id: "genderTypeIdentificationID", value: t("genderIdentity") },
  ];

  if (!validarEmail(personDataPost['Email'])) {
    return t("invalidEmail")
  }

  if (personDataPost["CpfCnpj"].length < 11) {
    return t("idSizeError")
  }

  for (const field of requiredFields) {
    if (!personDataPost[field.id]) {
      return `${field.value} ${t("requiredInput")}`;
    }
  }

  return null;
}

export function validateRequiredInput(personDataPost: any, inputName: string) {
  const requiredFields = [
    "Name",
    "Email",
    "CpfCnpj",
    "Birthdate",
    "Street",
    "Zipcode",
    "GeoUnitCityID",
    "GeoUnitCountryID",
    "State",
    "CityName",
    "Number",
    "ProfessionName",
    "RG",
    "State",
    "Neighborhood",
    "TitularRg",
    "TitularCpfCnpj",
    "Phone",
    "MobilePhone",
    "Birthdate",
    "genderTypeBiologicID",
    "genderTypeIdentificationID",
  ];

  if (requiredFields.includes(inputName) && !personDataPost[inputName]) {
    return true;
  }

  return false;
}
