import React, { ButtonHTMLAttributes } from 'react';
import './button.css';
import { ArrowCircleLeft, ArrowRight } from 'phosphor-react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  typeButton: 'link' | 'buttonRight' | 'buttonLeft';
  backgroundColor?: string;
  size?: 'small' | 'medium' | 'large';
  label?: string;
  onClick?: () => Promise<void> | void;
}

export const Button = ({
  size = 'medium',
  backgroundColor,
  label,
  typeButton,
  ...props
}: ButtonProps) => {
  if (typeButton === 'buttonRight') {
    return (
      <button
        type="button"
        className={[
          'storybook-button',
          'storybook-button-button',
          `storybook-button--${size}`
        ].join(' ')}
        {...props}
      >
        {label}
        <div className={['iconButton'].join(' ')}>
          <ArrowRight color="#FFF" weight="fill" size={16} />
        </div>
        <style jsx>{`
          button {
            background-color: ${backgroundColor};
          }
        `}</style>
      </button>
    );
  }
  if (typeButton === 'buttonLeft') {
    return (
      <button
        type="button"
        className={[
          'storybook-button-left',
          'storybook-button-button-left',
          `storybook-button--${size}`
        ].join(' ')}
        {...props}
      >
        {label}
        <div className={['iconButtonLeft'].join(' ')}>
          <ArrowCircleLeft color="#ea7526" weight="fill" size={16} />
        </div>
        <style jsx>{`
          button {
            background-color: ${backgroundColor};
          }
        `}</style>
      </button>
    );
  }

  if (typeButton === 'link') {
    return (
      <button
        type="button"
        className={['storybook-button', 'storybook-button-link'].join(' ')}
        {...props}
      >
        {label}
        <style jsx>{`
          button {
            background-color: ${backgroundColor};
          }
        `}</style>
      </button>
    );
  }
  return null;
};
