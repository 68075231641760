import axios from 'axios';
import { api } from './api';

export interface UserProps {
  token: string;
  expiration: string;
  userName: string;
  error?: [];
  items?: Array<{
    role: {
      active: string;
      description: string;
      id: string;
      name: string;
      normalizedName: string;
    };
    lstFeatures: Array<{
      featureID: number;
      featureGuid: string;
      name: string;
      route: string;
      featureParentID?: number;
      sort: number;
      showMenu: number;
      active: number;
      isScreen: number;
    }>;
  }>;
}

export async function authLoginService(request: any) {
  const login: any = await api.post(`/api/accounts/login`, request);
  const roles = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/features/GetAllRolesFeturesByUser`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${login.token}`
    }
  });

  const dataRoles: UserProps = roles.data;
  const merge: UserProps = {
    ...dataRoles,
    token: login.token,
    expiration: login.expiration,
    userName: login.userName
  }

  return merge
}

export function recoveryPasswordService(email: string) {
  const body = { Email: email }
  return api.put(`/api/auth/password-recovery`, body);
}

export function getFranchiseById(id: string) {
  return api.get(`/api/Franchise/getuserbyid?id=${id}`)
}

/* export function resetPasswordService(data: RecoveryTokenRequest) {
  return api.post(`/api/account/reset`, data);
} */

/* export function authChangePasswordService(request: ChangePasswordRequest) {
  setTokenToRequest();

  return api.put(`/api/account/change-password`, request);
} */


