import theme from "../../../style/public/styleGlobal";
import { useTranslation } from "react-i18next";
import { ThreeDots } from "react-loader-spinner";

interface LoaderProps {
  isLoading: boolean;
}

export function Loader({ isLoading }: LoaderProps) {
  const { t } = useTranslation();
  return isLoading ? (
    <div className="fixed bottom-20 right-1 flex flex-col justify-center items-center w-fit self-center bg-senario-700 p-5 rounded-lg">
      <span className="text-white">{t("loading")}</span>
      <ThreeDots
        visible={true}
        height="40"
        width="40"
        color={theme.palette.secondary.main}
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  ) : null;
}
