import { put, call } from "redux-saga/effects";
import { authLoginService } from "../../services/authServices";
import { loginFailure, loginSuccess } from "../reducers";

export function* fetchDataSaga(action: any): Generator<any, void, any> {
  try {
    const data = yield call(authLoginService, action.payload);
    yield put(loginSuccess(data));
  } catch (error: any) {
    yield put(loginFailure(error.message));
  }
}
