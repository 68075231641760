import { Button } from "../../ui/button";
import "./subFooterButtons.css";
import {
  ChevronLeft,
  Save,
  UserCheck,
  UserX,
} from "lucide-react";
import { TooltipComponent } from "../../../components/tooltip/tooltip";
import { useTranslation } from "react-i18next";

interface SubFooterButtonsProps {
  save: () => void;
  back: () => void;
  trash: () => void;
  inactive: () => void;
  status: boolean;
  id: any
}

export const SubFooterButtons = ({
  save,
  back,
  trash,
  inactive,
  status,
  id
}: SubFooterButtonsProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex p-1 bg-slate-200 justify-end fixed bottom-2 w-fit right-2 rounded-md">
      <div className=" p-2">
        <TooltipComponent message={t("save")}>
          <Button
            className="bg-primary-700 text-white shadow hover:bg-primary-700/90 h-9 px-3 py-2"
            onClick={save}
          >
            <Save />
          </Button>
        </TooltipComponent>
      </div>

      {id !== undefined && (
        <>
          {status ? (
            <div className=" p-2  ">
              <TooltipComponent message={t("disable")}>
                <Button
                  className="border border-gray-150 bg-white shadow-sm hover:text-accent-foreground h-9 px-3 py-2"
                  onClick={trash}
                >
                  <UserX color={"#878685"} />
                </Button>
              </TooltipComponent>
            </div>
          ) : (
            <div className=" p-2 ">
              <TooltipComponent message={t("enable")}>
                <Button
                  className="border border-gray-150 bg-white shadow-sm hover:text-accent-foreground h-9 px-3 py-2"
                  onClick={inactive}
                >
                  <UserCheck color={"#056125"} />
                </Button>
              </TooltipComponent>
            </div>
          )}
        </>
      )}

      <div className=" p-2 ">
        <TooltipComponent message={t("back")}>
          <Button
            className="border border-gray-150 bg-white shadow-sm hover:text-accent-foreground h-9 px-3 py-2"
            onClick={back}
          >
            <ChevronLeft color={"#878685"} />
          </Button>
        </TooltipComponent>
      </div>
    </div>
  );
};
