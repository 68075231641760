import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import InputText from '../../components/custom/InputText/text';
import { InputStyle } from './loginStyles';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

interface PropsModal {
    handleModal: any;
    open: boolean;
    onAccept: any;    
}

export default function ModalRecoveryPassword(props: PropsModal) {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');

    const handleChangeEmail = (e: any) =>{
        setEmail(e.target.value)
    }
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.handleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Recuperar senha
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Por favor, informe o endereço de e-mail associado à sua conta e clique em 'Enviar'. <br />
                        As instruções para redefinição de senha serão enviadas para o e-mail informado.
                    </Typography>
                    <InputText
                        id="txt-login-email"
                        style={InputStyle.inputResponsive}
                        startIcon="person"
                        name="email"
                        type="text"
                        placeholder={t('email')}
                        onChange={handleChangeEmail}
                        value={email}
                    />
                    <Button onClick={()=>props.onAccept(email)}>Confirmar</Button>
                    <Button onClick={props.handleModal}>Cancelar</Button>
                </Box>
            </Modal>
        </div>
    );
}