import { Link } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { FolderHeart } from "lucide-react";
import { useTranslation } from "react-i18next";

export function NotFound() {
    const {t} = useTranslation()
    return (
        <div className="flex flex-col bg-senario-700 w-screen h-screen justify-center items-center gap-8">
            <FolderHeart size={100} color="white" />
            <span className="text-white font-semibold text-lg">{t("pageNotFound")}</span>
            <Button
                type="button"
                className="bg-primary-700 text-white"
            >
                <Link to={'/'}>
                    {t("goBack")}
                </Link>
            </Button>
        </div>
    )
}