import { useTranslation } from "react-i18next";
import { IdentificationBadgeButton, PlusButton } from "../IconButton/Variants";
interface SubHeaderProps {
  isButton: boolean;
}
export const SubHeader = ({ isButton }: SubHeaderProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="justify-items-end flex w-auto px-4 mt-2 border-t-2 bg-white border-b-0 border-primary-700 rounded-md">
        <div className="lg:w-5/6 md:w-5/6 h-20 w-full flex items-center font-semibold">
          <span>{t("patientData")}</span>
        </div>
        <div className={[isButton === false ? `hidden` : " flex items-center"].join(" ")}>
          <div className="justify-end flex w-full ">
            <IdentificationBadgeButton
              title={t("medicalRecord")!}
              subTitle={t("goTo")!}
              onClick={() => alert("teste")}
            />
          </div>
        </div>
      </div>
    </>
  );
};
