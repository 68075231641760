import { Button } from '../../../components/ui/button';
import ParentComponent from '../../../components/utils/parentComponent';
import theme from '../../../style/public/styleGlobal';
import { PlusCircle } from 'phosphor-react';
import { Link } from 'react-router-dom';

export interface ItemButtonPros {
  btnTitle: string;
  btnSubTitle: string;
  childrenName: string;
  href: string
}

export function ItemButton({
  btnTitle,
  btnSubTitle,
  childrenName,
  href
}: ItemButtonPros) {
  return (
    <ParentComponent childrenName={childrenName}>
      <Link to={href}>
        <Button
          className="relative h-full border-none shadow justify-start bg-white rounded-2xl max-w-60 min-w-[205px] w-full "
        >
          <div className="relative flex-col h-full text-left py-2 whitespace-normal overflow-hidden break-words">
            <PlusCircle
              size={40}
              color={theme.palette.secondary.main}

            />
            <div className='flex-col relative flex gap-1'>
              <span className="text-xs text-primary-500">
                {btnSubTitle?.toUpperCase()}
              </span>
              <span className="lg:text-xl md:text-xl sm:text-lg">{btnTitle}</span>
            </div>
          </div>
        </Button>
      </Link>
    </ParentComponent>
  );
}
