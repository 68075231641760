import { api, apiMultipart } from "../api";
import { AspNetUserPersonProps, PutPersonProps } from "../requests/Person";

export function GetPatientById(id: number) {
  return api.get(`api/person/GetPersonById/${id}`);
}

export function createPerson(formData: any) {
  return apiMultipart.post("api/person/SavePerson", formData);
}

export function updatePerson(AspNetUserPersonProps: PutPersonProps) {
  return apiMultipart.put("api/person/PutPerson", AspNetUserPersonProps);
}

export function updateStatusPerson(personID: string, statusID: 1 | 2) {
  return api.put(`/api/person/PutStatusPerson/${personID}/${statusID}`);
}
