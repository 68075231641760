import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LoginResponse } from '../../models/response';
import { LoginRequest } from '../../models/request';
import { STORAGE_CURRENT_LOGGED_USER, STORAGE_TOKEN, clearStorage, saveStorage, saveStringStorage } from '../../services/storage';
import { UserProps } from '../../services/authServices';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

interface DataState {
  data: UserProps | null;
  body: LoginRequest | null
}

interface StateDefault {
  loading: boolean;
  error: any;
}

const initialState: StateDefault & DataState = {
  loading: false,
  error: null,
  data: null,
  body: null
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginRequestAction: (state, action: PayloadAction<LoginRequest>) => {
      state.loading = true;
      state.data = null;
      state.body = action.payload;
    },
    loginSuccess: (state, action: PayloadAction<UserProps>) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
      saveStringStorage(STORAGE_TOKEN,action.payload.token);
      saveStorage(STORAGE_CURRENT_LOGGED_USER, action.payload)
    },
    loginFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    logout:(state) =>{
      state.body = null;
      state.data = null;
      history.replace('/login')
      clearStorage();
    }
  },
});

export const { loginRequestAction, loginSuccess, loginFailure, logout } = loginSlice.actions;

export default loginSlice.reducer;