import { Card, CardContent, CardTitle } from '../../../components/ui/card';
import { ItemButton, ItemButtonPros } from '../ItemButton/ItemButton';
import ParentComponent from '../../../components/utils/parentComponent';

interface CardProps {
  btns: ItemButtonPros[];
  titleCard: string;
  childrenName: string;
}

export function OptionsCard({ btns, titleCard, childrenName }: CardProps) {
  return (
    <ParentComponent childrenName={childrenName}>
      <Card className="w-full relative lg:w-full md:w-80 m-2 bg-primary-600 border-none shadow-none rounded-2xl bg-opacity-40 min-w-[350px] whitespace-normal overflow-hidden break-words">
        <CardTitle className="relative py-6 mx-4 mb-4 border-primary-500 border-b-2 border-opacity-10">
          <span className="font-bold lg:text-2xl md:text-xl sm:text-lg">
            {titleCard}
          </span>
        </CardTitle>
        <CardContent className="relative gap-4 w-full md:grid-cols-1 lg:grid-cols-2 grid">
          {btns?.map((item, index) => (
            <ItemButton
              key={item.btnTitle + index}
              btnSubTitle={item.btnSubTitle}
              btnTitle={item.btnTitle}
              childrenName={item.childrenName}
              href={item.href}
            />
          ))}
        </CardContent>
      </Card>
    </ParentComponent>
  );
}
