import {
  differenceInYears,
  differenceInMonths,
  differenceInDays,
  isValid,
} from "date-fns";

export function calcularIdade(dataNascimento: Date) {
  // Obtém a data atual
  const dataAtual = new Date();

  if (!isValid(dataNascimento)) {
    return "";
  }

  // Calcula a diferença em anos, meses e dias
  const anos = differenceInYears(dataAtual, dataNascimento);
  const mesesRestantes = differenceInMonths(dataAtual, dataNascimento) % 12;
  const diasRestantes = differenceInDays(dataAtual, dataNascimento) % 30; // Aproximação de meses considerando 30 dias por mês

  // Verifica se a idade é menor que 1 ano
  if (anos < 1) {
    // Retorna a idade formatada sem os anos
    return `${mesesRestantes} mês(es) e ${diasRestantes} dia(s)`;
  }

  // Formata a string de idade
  const idadeFormatada = `${anos} ano(s), ${mesesRestantes} mês(es) e ${diasRestantes} dia(s)`;

  return idadeFormatada;
}
