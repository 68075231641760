import { Alert, Snackbar } from "@mui/material";

interface AlertProps {
  open: boolean;
  onClose: () => void;
  severity: "success" | "info" | "warning" | "error" | any;
  message: string;
}

export function AlertComponent({
  open,
  onClose,
  severity,
  message,
}: AlertProps) {
  return (
    <Snackbar
      key={"alert"}
      open={open}
      autoHideDuration={4000}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
