/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';

interface Store {
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
}

export const asideStateStore = create<Store>((set) => ({
  isOpen: true,
  setIsOpen: (status) => {
    set({ isOpen: status });
    if (!status) {
      setTimeout(() => {
        set({ isOpen: true });
      }, 5000);
    }
  }
}));
