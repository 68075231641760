import React from "react";
import { useTranslation } from "react-i18next";

interface DataTablePaginationProps {
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
  setNumberOfItems: (numberOfItems: number) => void;
}

const DataTablePagination: React.FC<DataTablePaginationProps> = ({
  itemsPerPage,
  totalItems,
  currentPage,
  onPageChange,
  setNumberOfItems,
}) => {
  const { t } = useTranslation();
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleClick = (page: number) => {
    onPageChange(page);
  };

  const handleNumberOfItems = (size: number) => {
    setNumberOfItems(size);
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    for (let i = 1; i <= totalPages; i++) {
      paginationItems.push(
        <li
          key={i}
          className={`text-sm hover:cursor-pointer ${
            i === currentPage ? "text-primary-700" : "text-white"
          }`}
          onClick={() => handleClick(i)}
        >
          {i}
        </li>
      );
    }
    return paginationItems;
  };

  return (
    <div className="flex px-4 py-2 shadow bg-senario-700 rounded-md items-center justify-between">
      <ul className="flex gap-2">
        {totalItems > 5 && (
          <span className="text-white text-sm">{t("numberOfItems")}: </span>
        )}
        {[5, 10, 15].map((item, index) => (
          <li
            key={item + index}
            className={`text-sm hover:cursor-pointer hover:text-slate-50 ${
              itemsPerPage === item
                ? "text-primary-700 hover:cursor-pointer"
                : "text-white"
            }`}
            onClick={() => handleNumberOfItems(item)}
          >
            {totalItems >= item ? item : ""}
          </li>
        ))}
      </ul>
      <ul className="flex gap-4 ">
        <li
          className={`text-white text-sm hover:cursor-pointer hover:text-slate-50 ${
            currentPage <= 1 ? "hidden" : ""
          }`}
          onClick={() => handleClick(currentPage - 1)}
        >
          {t("previous")}
        </li>
        {renderPaginationItems()}
        <li
          className={`text-white text-sm hover:cursor-pointer hover:text-slate-50 ${
            currentPage >= totalPages ? "hidden" : ""
          }`}
          onClick={() => handleClick(currentPage + 1)}
        >
          {t("next")}
        </li>
      </ul>
    </div>
  );
};

export default DataTablePagination;
