import Logo from "../../../assets/images/logo-full.png";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarSub,
  MenubarSubContent,
  MenubarSubTrigger,
  MenubarTrigger,
} from "../../../components/ui/menubar";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/avatar";
import { ChevronDown } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Cross2Icon, HamburgerMenuIcon } from "@radix-ui/react-icons";
import { asideStateStore } from "../../../store/zustand/asideState";
import { UserProps } from "../../../services/authServices";
import {
  STORAGE_CURRENT_LOGGED_USER,
  getStorage,
} from "../../../services/storage";
import { logout } from "../../../store/reducers";
import { handleChangeLanguage } from "../../../components/utils/functions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export function TopMenu() {
  // const route = useRouter();
  // const { user, logout } = authStore();
  const { setIsOpen, isOpen } = asideStateStore();
  // const { setDefault, lang } = languageStore();
  // const dict = getDictionaryUseClient(lang);

  const user: UserProps = getStorage(STORAGE_CURRENT_LOGGED_USER);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  return !user.token ? (
    <></>
  ) : (
    <nav className="flex w-full fixed justify-between bg-senario-700 h-14 items-center px-3 z-20">
      <div className="flex">
        <Button
          variant="ghost"
          size="sm"
          className="w-fit self-center bg-senario-700 hover:bg-primary-700 rounded-lg "
          onClick={() => setIsOpen(!isOpen)}
        >
          {!isOpen ? (
            <Cross2Icon className="h-5 w-5" color="white" />
          ) : (
            <HamburgerMenuIcon className="h-5 w-5" color="white" />
          )}
        </Button>
        <img src={Logo} alt="Logo Wellny" className="relative w-24 left-10" />
      </div>
      <Menubar className="gap-2 border-none bg-senario-700">
        <Avatar className="h-8 w-8">
          <AvatarImage src="https://github.com/shadcn.png" alt="@avatar" />
          <AvatarFallback>WY</AvatarFallback>
        </Avatar>
        <MenubarMenu>
          <MenubarTrigger className="text-white focus:text-white data-[state=open]:bg-primary-700 data-[state=open]:text-white">
            {user?.userName?.split("@")[0].toUpperCase()}
            <ChevronDown size={15} className="ml-2" />
          </MenubarTrigger>
          <MenubarContent className="bg-white hover:bg-white">
            <MenubarItem onClick={() => {}}>{t("myProfile")}</MenubarItem>
            <MenubarSub>
              <MenubarSubTrigger>{t("language")}</MenubarSubTrigger>
              <MenubarSubContent>
                <MenubarItem onClick={() => handleChangeLanguage("pt")}>
                  {t("portuguese")}
                </MenubarItem>
                <MenubarItem onClick={() => handleChangeLanguage("es")}>
                  {t("spanish")}
                </MenubarItem>
                <MenubarItem onClick={() => handleChangeLanguage("en")}>
                  {t("english")}
                </MenubarItem>
              </MenubarSubContent>
            </MenubarSub>
            <MenubarSeparator />
            <MenubarItem
              onClick={() => {
                dispatch(logout());
                history.replace("/login");
              }}
            >
              {t("logout")}
            </MenubarItem>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
    </nav>
  );
}
