import { ThemeProvider } from '@mui/material/styles';
import theme from '../style/public/styleGlobal';
import Routes from '../routes/routes';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../store/store';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const App = () => (
  <Provider store={store}>
    <BrowserRouter basename='/'>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
          <Routes />
        </LocalizationProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);
export default App;
