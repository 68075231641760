import React from "react";
import * as Icons from "phosphor-react";
import { Home, Settings, Users } from "lucide-react";
import {
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarShortcut,
  MenubarTrigger,
} from "../../../components/ui/menubar";
import ParentComponent from "../../../components/utils/parentComponent";
import { Link } from "react-router-dom";

export interface MenuItems {
  title: string;
  childrenNameMenu: string;
  icon: "home" | "user" | "settings";
  showMenu: 1 | 0;
  href: string;
  items: {
    id: number;
    childrenNameItem: string;
    childrenOf: number;
    title: string;
    href: string;
    description: string;
    showMenu: 1 | 0;
  }[];
}

export interface MenuLateralProps {
  btn: MenuItems[];
  isHide: boolean;
}

export function MenuItem({ btn, isHide }: MenuLateralProps) {
  const MappingIcon = {
    home: Home,
    user: Users,
    settings: Settings,
  };

  return (
    <>
      {btn.map((item, index) => {
        const IconComponent = MappingIcon[item.icon];

        return (
          <React.Fragment key={item.title + "-" + index}>
            <ParentComponent childrenName={item.childrenNameMenu}>
              <div className={`${index === 0 ? "ml-1" : "ml-0"}`}>
                <MenubarMenu>
                  {
                    item.items.some(item => item.showMenu === 1) ?
                      <>
                        <MenubarTrigger
                          className="gap-1 text-slate-900 hover:cursor-pointer"
                          onClick={() => console.log("clicou")}
                        >
                          <div>
                            <IconComponent
                              color="#163E4C"
                              size={20}
                              strokeWidth={2.5}
                            />
                          </div>
                          {!isHide && item.title}
                        </MenubarTrigger>
                        <MenubarContent className="bg-white ">
                          {item.items.map((component) => (
                            <ParentComponent
                              key={component.title}
                              childrenName={component.childrenNameItem}
                            >
                              <Link to={component.href}>
                                <MenubarItem>
                                  {component.title}
                                  <MenubarShortcut>
                                    <Icons.DotsNine color="#163E4C" />
                                  </MenubarShortcut>
                                </MenubarItem>
                              </Link>
                            </ParentComponent>
                          ))}
                        </MenubarContent>
                      </>
                      :
                      <Link to={item.href}>
                        <MenubarTrigger
                          className="gap-1 text-slate-900 hover:cursor-pointer"
                          onClick={() => console.log("clicou")}
                        >
                          <div>
                            <IconComponent
                              color="#163E4C"
                              size={20}
                              strokeWidth={2.5}
                            />
                          </div>
                          {!isHide && item.title}
                        </MenubarTrigger>
                      </Link>
                  }
                </MenubarMenu>
              </div>
            </ParentComponent>
          </React.Fragment>
        );
      })}
    </>
  );
}
