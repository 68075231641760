import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    common: { black: '#000', white: '#fff' },
    background: { paper: '#fff', default: '#fafafa' },
    primary: {
      main: '#002040',
      light: '#003366'
    },
    secondary: {
      main: '#EA7526',
      contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    text: {
      primary: 'rgba(1, 1, 1, 1)',
      secondary: 'rgba(153, 153, 153, 1)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    }
  },
  typography: {
    fontSize: 13,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  }
});

export default theme;
