import Webcam from "react-webcam";
import { SubHeader } from "../../../components/custom/subHeader/SubHeader";
import { InputTextForm } from "../../../components/custom/InputTextForm";
import { SubFooterButtons } from "../../../components/custom/SubFooterButtons/SubFooterButtons";
import { useTranslation } from "react-i18next";
import { Camera, CircleUserIcon, X } from "lucide-react";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "../../../components/ui/button";
import { calcularIdade } from "../../../components/utils/date/calcAge";
import PhoneNumberInput from "../../../components/custom/InputCountry/InputCountry";
import { titularOptions } from "../../../components/utils/titularOptions";
import { SearchButton } from "../../../components/custom/IconButton/Variants";
import { RouteComponentProps } from "react-router-dom";
import {
  GetPatientById,
  createPerson,
  updatePerson,
  updateStatusPerson,
} from "../../../services/patients/patientList";
import { PatientsResponseProps } from "@/services/response/patients";
import { createBrowserHistory } from "history";
import { GetAllGenders } from "../../../services/gender";
import { GetAddresssByZipcode } from "../../../services/address/address";
import { AddressProps } from "../../../services/address/addressTypes";
import { formatCPF, formatRG } from "../../../components/utils/formatID";
import { PutPersonProps } from "../../../services/requests/Person";
import { Loader } from "../../../components/custom/Loader/Loader";
import { AlertComponent } from "../../../components/alert/alert";
import Dropdown from "../../../components/dropdown";
import { TextField } from "@mui/material";
import {
  RemoveCountryCode,
} from "../../../components/utils/countryCodeValidator";
import {
  validateRequiredInput,
  validateRequiredPatientRegisterInput,
} from "../../../components/utils/functions";
import dayjs from "dayjs";
import { format } from "date-fns";
const { v4: uuidv4 } = require('uuid');

const genderExample = [
  {
    value: 1,
    label: "Masculino",
  },
  {
    value: 2,
    label: "Feminino",
  },
];

const videoConstraints = {
  width: 1280,
  height: 1280,
  facingMode: "user"
};

const InitialUserData = {
  AspNetUserStatusID: 0,
  AppID: "",
  SystemTypeID: 0,
  Name: "",
  SocialName: "",
  Email: "",
  CpfCnpj: "",
  TitularCpfCnpj: "",
  RG: "",
  TitularRg: "",
  PersonTypeID: 0,
  ProfessionName: "",
  Birthdate: null,
  FilePhoto: {
    File: "",
    FileName: "",
  },
  ProfilePhoto: "",
  Phone: "",
  MobilePhone: "",
  genderTypeBiologicID: 0,
  genderTypeIdentificationID: 0,
  Street: "",
  Zipcode: "",
  Complement: "",
  Neighborhood: "",
  GeoUnitCityID: 0,
  GeoUnitCountryID: 0,
  State: "",
  CityName: "",
  Number: "",
  CreateDate: new Date()
};

interface LocationState {
  dados: string;
}

const NewPatient: React.FC<RouteComponentProps<{}, {}, LocationState>> = ({ location }) => {
  const { t } = useTranslation();

  const countriesExemples = [
    {
      value: 2,
      label: t("brazil"),
    },
    {
      value: 13,
      label: t("spain"),
    },
    {
      value: 12,
      label: t("usa"),
    },
  ];

  const { id }: any = location.state || {}

  const history = createBrowserHistory();

  const webcamRef = useRef<any>(null);

  const [personDataResponse, setPersonDataResponse] =
    useState<PatientsResponseProps>();
  const [personDataPost, setPersonDataPost] = useState<any>(InitialUserData);
  const [personDataUpdate, setPersonDataUpdate] = useState<PutPersonProps>();

  const [isUpdateData, setIsUpdateData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasToValidate, setHasToValidate] = useState<boolean>(false);

  const [openCamera, setOpenCamera] = useState<boolean>(false);
  const [photoFile, setPhotoFile] = useState<any>(null);
  const [cameraFile, setCameraFile] = useState<any>(null);

  const [genders, setGenders] = useState([{ label: "", value: 0 }]);

  const [codePhone, setCodePhone] = useState("");
  const [codeModilePhone, setCodeModilePhone] = useState("");

  const [toast, setToast] = useState({
    open: false,
    severity: "error",
    message: "success",
  });

  // Função para converter blob em objeto de arquivo
  const blobToFile = async (blobUrl: string) => {
    const guid = uuidv4();
    if (blobUrl) {
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      return new File([blob], guid);
    }
    return new File([], 'empty');
  };

  // Função para converter uma string base64 em um objeto de arquivo
  const base64toFile = async (base64String: any, filename: string) => {
    const guid = uuidv4();
    if (base64String) {
      const base64WithoutPrefix = base64String?.split(';base64,').pop();
      const byteCharacters = atob(base64WithoutPrefix);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray]);
      return new File([blob], guid);
    }
    return new File([], 'empty');

  };

  async function handleCreatePerson() {
    const isEmptyInputs = validateRequiredPatientRegisterInput(personDataPost, t);

    if (isEmptyInputs) {
      setToast({
        message: t("hasEmptyInputsWarning"),
        open: true,
        severity: "warning",
      });
      setToast({
        message: isEmptyInputs,
        open: true,
        severity: "warning",
      });
      setHasToValidate(true);
      return;
    }

    if (personDataResponse?.personID) {
      const updateFormData: any = new FormData();
      const guid = uuidv4();
      const filename = guid
      const base64Image = await base64toFile(cameraFile, filename);
      const blobImage = await blobToFile(previewURL);

      if (base64Image.name !== 'empty' || blobImage.name !== 'empty') {
        updateFormData.append('FilePhoto.File', cameraFile ? base64Image : blobImage, filename);
      }

      updateFormData.append('PersonID', personDataUpdate?.personID);
      updateFormData.append('AddressID', personDataUpdate?.addressID);
      updateFormData.append('AspNetUserStatusID', personDataUpdate?.aspNetUserStatusID);
      updateFormData.append('AppID', personDataUpdate?.appID);
      updateFormData.append('SystemTypeID', personDataUpdate?.systemTypeID);
      updateFormData.append('Name', personDataUpdate?.name);
      updateFormData.append('Email', personDataUpdate?.email);
      updateFormData.append('CpfCnpj', personDataUpdate?.cpfCnpj);
      updateFormData.append('TitularCpfCnpj', personDataUpdate?.titularCpfCnpj);
      updateFormData.append('RG', personDataUpdate?.rg);
      updateFormData.append('TitularRg', personDataUpdate?.titularRg);
      updateFormData.append('PersonTypeID', personDataUpdate?.personTypeID);
      updateFormData.append('Birthdate', personDataUpdate?.birthdate);
      updateFormData.append('ProfilePhoto', personDataUpdate?.profilePhoto);
      updateFormData.append('Phone', codePhone !== "" ? codePhone + RemoveCountryCode(personDataUpdate?.phone!).phoneNumber : RemoveCountryCode(personDataUpdate?.phone!).countryCode + RemoveCountryCode(personDataUpdate?.phone!).phoneNumber);
      updateFormData.append('MobilePhone', codeModilePhone !== "" ? codeModilePhone + RemoveCountryCode(personDataUpdate?.mobilePhone!).phoneNumber : RemoveCountryCode(personDataUpdate?.mobilePhone!).countryCode + RemoveCountryCode(personDataUpdate?.mobilePhone!).phoneNumber);
      updateFormData.append('GenderTypeBiologicID', personDataUpdate?.genderTypeBiologicID);
      updateFormData.append('GenderTypeIdentificationID', personDataUpdate?.genderTypeIdentificationID);
      updateFormData.append('Street', personDataUpdate?.street);
      updateFormData.append('Zipcode', personDataUpdate?.zipcode);
      updateFormData.append('Complement', personDataUpdate?.complement);
      updateFormData.append('Neighborhood', personDataUpdate?.neighborhood);
      updateFormData.append('Number', personDataUpdate?.number);
      updateFormData.append('GeoUnitCityID', personDataUpdate?.geoUnitCityID);
      updateFormData.append('GeoUnitCountryID', personDataUpdate?.geoUnitCountryID);
      updateFormData.append('State', personDataUpdate?.state);
      updateFormData.append('ProfessionName', personDataUpdate?.professionName);
      updateFormData.append('SocialName', personDataUpdate?.socialName);
      updateFormData.append('CreateDate', personDataUpdate?.createDate);

      await updatePerson(updateFormData)
        .then((response) => {
          setIsUpdateData(!isUpdateData);
          setToast({
            open: true,
            message: "saveSuccess",
            severity: "success",
          });
        })
        .catch((e) => {
          console.log(e);
          setToast({
            open: true,
            message: "saveError",
            severity: "error",
          });
        });
    } else {
      const formData = new FormData();
      const guid = uuidv4();
      const filename = guid
      const base64Image = await base64toFile(cameraFile, filename);
      const blobImage = await blobToFile(previewURL);

      if (base64Image.name !== 'empty' && blobImage.name !== 'empty') {
        formData.append('FilePhoto.File', cameraFile ? base64Image : blobImage, filename);
      }

      formData.append("AspNetUserStatusID", personDataPost.AspNetUserStatusID);
      formData.append("AppID", personDataPost.AppID);
      formData.append("SystemTypeID", personDataPost.SystemTypeID);
      formData.append("Name", personDataPost.Name);
      formData.append("SocialName", personDataPost.SocialName);
      formData.append("Email", personDataPost.Email);
      formData.append("CpfCnpj", personDataPost.CpfCnpj);
      formData.append("TitularCpfCnpj", personDataPost.TitularCpfCnpj);
      formData.append("RG", personDataPost.RG);
      formData.append("TitularRg", personDataPost.TitularRg);
      formData.append("PersonTypeID", personDataPost.PersonTypeID);
      formData.append("ProfessionName", personDataPost.ProfessionName);
      formData.append("Birthdate", personDataPost.Birthdate);

      formData.append("Phone", codePhone + personDataPost.Phone);
      formData.append(
        "MobilePhone",
        codeModilePhone + personDataPost.MobilePhone
      );
      formData.append(
        "genderTypeBiologicID",
        personDataPost.genderTypeBiologicID
      );
      formData.append(
        "genderTypeIdentificationID",
        personDataPost.genderTypeIdentificationID
      );
      formData.append("Street", personDataPost.Street);
      formData.append("Zipcode", personDataPost.Zipcode);
      formData.append("Complement", personDataPost.Complement);
      formData.append("Neighborhood", personDataPost.Neighborhood);
      formData.append("GeoUnitCityID", personDataPost.GeoUnitCityID);
      formData.append("GeoUnitCountryID", personDataPost.GeoUnitCountryID);
      formData.append("State", personDataPost.State);
      formData.append("CityName", personDataPost.CityName);
      formData.append("Number", personDataPost.Number);

      await createPerson(formData)
        .then((response) => {
          setIsUpdateData(!isUpdateData);
          setToast({
            open: true,
            message: "saveSuccess",
            severity: "success",
          });
          history.goBack();
        })
        .catch((e) => {
          setToast({
            open: true,
            message: "saveError",
            severity: "error",
          });
        });

    }
  }

  useEffect(() => {
    setPersonDataPost({
      ...personDataPost,
      AspNetUserStatusID: personDataResponse?.aspNetUserStatusID || 1,
      AppID: personDataResponse?.appID || "",
      SystemTypeID: 1,
      Name: personDataResponse?.name || "",
      Email: personDataResponse?.email || "",
      CpfCnpj: personDataResponse?.cpfCnpj || "",
      TitularCpfCnpj: personDataResponse?.titularCpfCnpj || "",
      RG: personDataResponse?.rg || "",
      TitularRg: personDataResponse?.titularRg || "",
      PersonTypeID: Number(personDataResponse?.personTypeID) || 1,
      ProfessionName: personDataResponse?.professionName || "",
      Birthdate: personDataResponse?.birthdate || "",
      FilePhoto: {
        File: "",
        FileName: "profilePhoto",
      },
      ProfilePhoto: personDataResponse?.profilePhoto || "",
      Phone: personDataResponse?.phone || "",
      MobilePhone: personDataResponse?.mobilePhone || "",
      genderTypeBiologicID: Number(personDataResponse?.genderTypeBiologicID),
      genderTypeIdentificationID: Number(
        personDataResponse?.genderTypeIdentificationID
      ),
      Street: personDataResponse?.address?.street || "",
      Zipcode: personDataResponse?.address?.zipcode || "",
      Complement: personDataResponse?.address?.complement || "",
      Neighborhood: personDataResponse?.address?.neighborhood || "",
      GeoUnitCityID: Number(
        personDataResponse?.address.city.geoUnitCityID || ""
      ),
      GeoUnitCountryID: personDataResponse?.address.city.geoUnitCountryID || 0,
      State: personDataResponse?.address?.city?.state || "",
      CityName: personDataResponse?.address.city.cityName || "",
      Number: personDataResponse?.address.number || "",
      SocialName: personDataResponse?.socialName || "",
    });
  }, [personDataResponse]);

  useEffect(() => {
    setPersonDataUpdate({
      ...personDataUpdate,
      personID: personDataResponse?.personID,
      addressID: personDataResponse?.addressID,
      aspNetUserStatusID: personDataResponse?.aspNetUserStatusID,
      appID: personDataResponse?.appID || "",
      systemTypeID: personDataResponse?.systemTypeID,
      name: personDataPost?.Name,
      email: personDataPost?.Email,
      cpfCnpj: personDataPost?.CpfCnpj,
      titularCpfCnpj: personDataPost?.TitularCpfCnpj,
      rg: personDataPost?.RG,
      titularRg: personDataPost?.TitularRg,
      personTypeID: personDataPost?.PersonTypeID,
      birthdate: personDataPost?.Birthdate,
      profilePhoto: personDataPost?.ProfilePhoto,
      phone:
        codePhone !== ""
          ? codePhone + RemoveCountryCode(personDataPost?.Phone).phoneNumber
          : RemoveCountryCode(personDataPost?.Phone).countryCode +
          RemoveCountryCode(personDataPost?.Phone).phoneNumber,
      mobilePhone:
        codeModilePhone !== ""
          ? codeModilePhone +
          RemoveCountryCode(personDataPost?.MobilePhone).phoneNumber
          : RemoveCountryCode(personDataPost?.MobilePhone).countryCode +
          RemoveCountryCode(personDataPost?.MobilePhone).phoneNumber,
      genderTypeBiologicID: personDataPost?.genderTypeBiologicID,
      genderTypeIdentificationID: personDataPost?.genderTypeIdentificationID,
      street: personDataPost?.Street,
      zipcode: personDataPost?.Zipcode,
      complement: personDataPost?.Complement,
      neighborhood: personDataPost?.Neighborhood,
      number: personDataPost?.Number,
      geoUnitCityID: personDataPost?.GeoUnitCityID,
      geoUnitCountryID: personDataPost?.GeoUnitCountryID,
      state: personDataPost?.State,
      professionName: personDataPost?.ProfessionName,
      socialName: personDataPost?.SocialName,
      createDate: personDataResponse?.createdDate
    });
  }, [personDataResponse, personDataPost, codePhone]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      GetPatientById(id)
        .then((response: any) => {
          setPersonDataResponse(response.person[0]);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e);
        });
    }
    GetAllGenders()
      .then((response: any) => {
        const genders = response.map(
          (item: { genderTypeID: any; name: any }) => ({
            value: Number(item.genderTypeID),
            label: item.name,
          })
        );
        setGenders(genders);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }, [id, isUpdateData]);

  function handleFileSelected(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.currentTarget;
    setCameraFile(null);
    setPhotoFile(null);
    setOpenCamera(false);

    if (!files) {
      return;
    }

    const selectedFile = files[0];
    setPhotoFile(selectedFile);
  }

  const previewURL: any = useMemo(() => {
    if (!cameraFile) {
      if (personDataResponse?.profilePhoto && !photoFile) {
        return personDataResponse?.profilePhoto;
      } else {
        if (!photoFile) {
          return null;
        }
        return URL.createObjectURL(photoFile);
      }
    }
    return null
  }, [photoFile, personDataResponse?.profilePhoto, cameraFile]);

  const capture = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setCameraFile(imageSrc);
      setOpenCamera(false);
      setPhotoFile(null);
    } else {
      console.error("Referência 'webcamRef.current' é nula.");
    }
  };

  function handleAddressZipcode() {
    setIsLoading(true)
    GetAddresssByZipcode(personDataPost.Zipcode)
      .then((res: any) => {
        const address: AddressProps = res;
        setIsLoading(false)
        setPersonDataPost({
          ...personDataPost,
          GeoUnitCityID: address.geoUnitCityID,
          Street: address.street,
          Neighborhood: address.neighborhood,
          State: address.city.state,
          CityName: address.city.cityName,
        });
        setToast({
          open: true,
          message: "searchAddressSuccess",
          severity: "success",
        });
      })
      .catch((e) => {
        setIsLoading(false)
        setToast({
          open: true,
          message: "searchAddressError",
          severity: "error",
        });
        setPersonDataPost({
          ...personDataPost,
          GeoUnitCityID: '',
          Street: '',
          Neighborhood: '',
          State: '',
          CityName: '',
        });
      });
  }

  function handleUpdateStatusPerson(statusID: 1 | 2, personID: string) {
    setIsLoading(true);
    updateStatusPerson(personID, statusID)
      .then((response) => {
        setIsLoading(false);
        setIsUpdateData(!isUpdateData);
        if (statusID === 1) {
          setToast({
            open: true,
            message: "activeSuccess",
            severity: "success",
          });
        } else {
          setToast({
            open: true,
            message: "inactiveSuccess",
            severity: "success",
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setToast({ open: true, message: "saveError", severity: "error" });
        setIsLoading(false);
      });
  }

  function validateInput(personDataPost: any, input: string) {
    if (hasToValidate) {
      return validateRequiredInput(personDataPost, input);
    }
    return;
  }

  return (
    <section className="relative w-screen max-w-screen-2xl my-4 bg-gray-50 p-3 justify-center pt-16 md:ml-16 sm:ml-16 xs:ml-16">
      <div className="flex mb-5 px-4">
        <span>
          {t("patients")} / <b>{t("patientRegistration")}</b>
        </span>
      </div>
      <div className="bg-white shadow pb-4 rounded-md">
        <SubHeader isButton={true} />
        <div className="grid grid-cols-2 gap-3 my-4 px-4 items-center">
          <TextField
            className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
            label={t("fullName")}
            value={personDataPost?.Name}
            onChange={(e) =>
              setPersonDataPost({ ...personDataPost, Name: e.target.value })
            }
            size="small"
            required
            error={validateInput(personDataPost, "Name")}
          />

          <TextField
            className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
            label={t("socialName")!}
            value={personDataPost?.SocialName}
            onChange={(e) =>
              setPersonDataPost({
                ...personDataPost,
                SocialName: e.target.value,
              })
            }
            size="small"
          />

          <div className="flex items-end gap-2">
            <InputTextForm
              label={`${t("birthdate")}*`}
              typeInput="date"
              placeholder={`${t("birthdate")}`}
              dateValue={personDataPost?.Birthdate ? dayjs(personDataPost?.Birthdate) : null}
              onChangeDate={(value) =>
                setPersonDataPost({
                  ...personDataPost,
                  Birthdate: value?.format('YYYY-MM-DD')
                })
              }
              maxDate={dayjs(new Date())}
            />
            <p className="text-sm hidden sm:block">
              {calcularIdade(new Date(personDataPost?.Birthdate))}
            </p>
          </div>

          <TextField
            className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
            label={t("profession")!}
            value={personDataPost?.ProfessionName}
            onChange={(e) =>
              setPersonDataPost({
                ...personDataPost,
                ProfessionName: e.target.value,
              })
            }
            size="small"
            required
            error={validateInput(personDataPost, "ProfessionName")}
          />

          <Dropdown
            value={personDataPost.genderTypeIdentificationID}
            options={genders}
            label={t("genderIdentity") + "*"}
            onChange={(res: any) =>
              setPersonDataPost({
                ...personDataPost,
                genderTypeIdentificationID: Number(res),
              })
            }
            error={validateInput(personDataPost, "genderTypeIdentificationID")}
          />

          {/* <div /> */}

          <Dropdown
            value={personDataPost.genderTypeBiologicID}
            options={genderExample}
            label={t("biologicalSex") + "*"}
            onChange={(res) =>
              setPersonDataPost({
                ...personDataPost,
                genderTypeBiologicID: res,
              })
            }
            error={validateInput(personDataPost, "genderTypeBiologicID")}
          />

          <TextField
            className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
            label={t("ID")!}
            value={formatCPF(personDataPost?.CpfCnpj)}
            onChange={(e) =>
              setPersonDataPost({ ...personDataPost, CpfCnpj: e.target.value })
            }
            inputProps={{ maxLength: 11 }}
            size="small"
            required
            error={validateInput(personDataPost, "CpfCnpj")}
          />

          <Dropdown
            value={personDataPost.TitularCpfCnpj}
            options={titularOptions(t)}
            label={t("cpfHolder") + "*"}
            onChange={(res) =>
              setPersonDataPost({ ...personDataPost, TitularCpfCnpj: res })
            }
            error={validateInput(personDataPost, "TitularCpfCnpj")}
          />

          <TextField
            className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
            label={t("securityNumber")!}
            value={formatRG(personDataPost?.RG)}
            onChange={(e) =>
              setPersonDataPost({ ...personDataPost, RG: e.target.value })
            }
            size="small"
            inputProps={{ maxLength: 9 }}
            required
            error={validateInput(personDataPost, "RG")}
          />

          <Dropdown
            value={personDataPost.TitularRg}
            options={titularOptions(t)}
            label={t("securityNumberHolder") + "*"}
            onChange={(res) =>
              setPersonDataPost({ ...personDataPost, TitularRg: res })
            }
            error={validateInput(personDataPost, "TitularRg")}
          />

          <div className="grid grid-cols-2 gap-2 my-6 items-center">
            <label
              htmlFor="photo"
              className="relative flex rounded-md overflow-hidden aspect-square border-gray-150 cursor-pointer border-2 border-dashed text-sm flex-col gap-2 items-center justify-center text-muted-foreground hover:bg-secondary/10"
            >
              {openCamera && !photoFile ? (
                <Webcam
                  audio={false}
                  height={1280}
                  ref={webcamRef}
                  screenshotFormat="image/png"
                  width={1280}
                  mirrored
                  videoConstraints={videoConstraints}
                />
              ) : (
                <>
                  {previewURL ? (
                    <img
                      src={previewURL}
                      alt="Preview"
                      className="w-full h-full object-cover"
                    />
                  ) : cameraFile ? (
                    <img
                      src={cameraFile}
                      alt="Preview"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="flex flex-col items-center">
                      <CircleUserIcon
                        className="md:h-28 md:w-28 xs:h-16 xs:w-16"
                        color="#e3e2e2"
                        fill="#9c9c9c"
                      />
                      <span className="text-center">
                        {t("selectPhoto")}
                      </span>
                    </div>
                  )}
                </>
              )}
            </label>
            <input
              type="file"
              id="photo"
              accept="image/png"
              className="sr-only"
              onChange={handleFileSelected}
            />
            <div className="flex-col flex gap-4">
              <Button
                className="rounded-full w-14 h-14 border-gray-150 border bg-senario-700 hover:bg-senario-701"
                onClick={
                  openCamera
                    ? capture
                    : () => {
                      setPhotoFile(null);
                      setOpenCamera(true);
                    }
                }
              >
                <Camera color="white" />
              </Button>
              {openCamera && (
                <Button
                  className="rounded-full w-14 h-14 border-gray-150 border bg-red-500 hover:bg-senario-701"
                  onClick={() => setOpenCamera(false)}
                >
                  <X color="white" />
                </Button>
              )}
            </div>
          </div>
          <div className="gap-4 flex flex-col">
            <div>
              <p className="font-arial ml-1 mb-1 font-normal text-xs text-slate-600">
                {t("phone")} 1 *
              </p>
              <PhoneNumberInput
                value={personDataPost?.Phone}
                phoneNumber={personDataPost?.Phone}
                setCode={(code) => setCodePhone(code)}
                onChange={(e: { target: { value: any } }) => {
                  setPersonDataPost({
                    ...personDataPost,
                    Phone: e.target.value,
                  });
                }}
                error={validateInput(personDataPost, "Phone")}
              />
            </div>
            <div>
              <p className="font-arial ml-1 mb-1 font-normal text-xs text-slate-600">
                {t("phone")} 2 *
              </p>
              <PhoneNumberInput
                value={personDataPost?.MobilePhone}
                phoneNumber={personDataPost?.MobilePhone}
                setCode={(code) => setCodeModilePhone(code)}
                onChange={(e: { target: { value: any } }) =>
                  setPersonDataPost({
                    ...personDataPost,
                    MobilePhone: e.target.value,
                  })
                }
                error={validateInput(personDataPost, "MobilePhone")}
              />
            </div>

            <TextField
              className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
              label={t("email")!}
              value={personDataPost?.Email}
              onChange={(e) =>
                setPersonDataPost({ ...personDataPost, Email: e.target.value })
              }
              size="small"
              required
              error={validateInput(personDataPost, "Email")}
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3 my-4 mb-12 items-center bg-white py-4 px-4 rounded-md shadow">
        <b>{t("address")}</b>
        <div />

        <Dropdown
          value={personDataPost.GeoUnitCountryID}
          options={countriesExemples}
          label={t("country") + "*"}
          onChange={(res) =>
            setPersonDataPost({
              ...personDataPost,
              GeoUnitCountryID: Number(res),
            })
          }
          error={validateInput(personDataPost, "GeoUnitCountryID")}
        />

        <div className="flex gap-4">
          <TextField
            className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
            label={t("zipCode")!}
            value={personDataPost?.Zipcode}
            onChange={(e) =>
              setPersonDataPost({ ...personDataPost, Zipcode: e.target.value })
            }
            size="small"
            sx={{ width: "100%" }}
            required
            error={validateInput(personDataPost, "Zipcode")}
            inputProps={{ maxLength: 8 }}
          />
          <SearchButton onClick={handleAddressZipcode} />
        </div>
        <TextField
          size="small"
          className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
          label={t("city")!}
          value={personDataPost?.CityName}
          onChange={(e) =>
            setPersonDataPost({ ...personDataPost, CityName: e.target.value })
          }
          required
          error={validateInput(personDataPost, "CityName")}
        />
        <TextField
          size="small"
          className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
          label={t("state")!}
          value={personDataPost?.State}
          onChange={(e) =>
            setPersonDataPost({ ...personDataPost, State: e.target.value })
          }
          required
          error={validateInput(personDataPost, "State")}
        />
        <TextField
          size="small"
          className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
          label={t("street")!}
          value={personDataPost?.Street}
          onChange={(e) =>
            setPersonDataPost({ ...personDataPost, Street: e.target.value })
          }
          required
          error={validateInput(personDataPost, "Street")}
        />
        <TextField
          size="small"
          className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
          label={t("number")!}
          value={personDataPost?.Number}
          onChange={(e) =>
            setPersonDataPost({ ...personDataPost, Number: e.target.value })
          }
          required
          error={validateInput(personDataPost, "Number")}
        />
        <TextField
          size="small"
          className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
          label={t("complement")!}
          value={personDataPost?.Complement}
          onChange={(e) =>
            setPersonDataPost({ ...personDataPost, Complement: e.target.value })
          }
        />
        <TextField
          size="small"
          className="border-gray-150 max-w-screen-md placeholder:text-gray-150 bg-white"
          label={t("neighborhood")!}
          value={personDataPost?.Neighborhood}
          onChange={(e) =>
            setPersonDataPost({
              ...personDataPost,
              Neighborhood: e.target.value,
            })
          }
          required
          error={validateInput(personDataPost, "Neighborhood")}
        />
      </div>

      <SubFooterButtons
        id={id}
        save={() => handleCreatePerson()}
        back={() => history.goBack()}
        inactive={() =>
          handleUpdateStatusPerson(1, String(personDataResponse?.personID))
        }
        trash={() =>
          handleUpdateStatusPerson(2, String(personDataResponse?.personID))
        }
        status={personDataPost.AspNetUserStatusID === 1 ? true : false}
      />

      <Loader isLoading={isLoading} />
      <AlertComponent
        open={toast.open}
        onClose={() => setToast({ ...toast, open: false })}
        severity={toast.severity}
        message={t(toast.message)}
      />
    </section>
  );
}
export default NewPatient