import { MenuItems } from "./NavigationMenuItem";
import { useTranslation } from "react-i18next";

export const useData = () => {
  const { t } = useTranslation();

  const menuData: MenuItems[] = [
    {
      title: "Home",
      childrenNameMenu: "t.feature.home",
      icon: "home",
      showMenu: 1,
      href: "/home",
      items: [
        {
          id: 1,
          childrenOf: 0,
          title: "Home",
          href: "/home",
          description: "Descrição do Item 1.1",
          childrenNameItem: "t.feature.home",
          showMenu: 0
        },
      ],
    },
    {
      title: t("patients"),
      childrenNameMenu: "t.feature.patients",
      icon: "user",
      showMenu: 1,
      href: "/patients/patients-list",
      items: [
        {
          id: 1,
          childrenOf: 0,
          title: t("patientRegistration"),
          href: "/patient/register",
          description: "Descrição do Item 1.1",
          childrenNameItem: "t.feature.patients",
          showMenu: 0
        },
        {
          id: 2,
          childrenOf: 0,
          title: t("patientsSearch"),
          href: "/patients/patients-list",
          description: "Descrição do Item 1.2",
          childrenNameItem: "t.feature.patientslist",
          showMenu: 0
        },
      ],
    },
    {
      title: t("settings"),
      childrenNameMenu: "t.feature.settings",
      icon: "settings",
      showMenu: 1,
      href: "/settings",
      items: [
        {
          id: 3,
          childrenOf: 0,
          title: t("settings"),
          href: "/",
          description: "Descrição do Item 2.1",
          childrenNameItem: "t.feature.settings",
          showMenu: 0
        },
      ],
    },
  ];

  return { menuData } as const;
};
