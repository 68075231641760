export function formatCPF(cpf: string): string {
    cpf = cpf.replace(/\D/g, ''); // Remove caracteres não numéricos

    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

export function formatRG(rg: string): string {
    rg = rg.replace(/\D/g, ''); // Remove caracteres não numéricos

    // Adapte conforme o formato do RG da sua região
    return rg.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
}