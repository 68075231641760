import { PayloadAction, createSlice } from '@reduxjs/toolkit';


interface DataState {
 data: string | null;
 body: string | null
}

interface StateDefault {
  loading: boolean;
  error: any;
}

const initialState: StateDefault & DataState = {
  loading: false,
  error: null,
  data: null,
  body: null
};

const patientAutorizationSlice = createSlice({
  name: 'patientAutorization',
  initialState,
  reducers: {
    patientAutorizationAction: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.data = null;
      state.body = action.payload
    },
    patientAutorizationSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.data = action.payload;
    },
    patientAutorizationFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { patientAutorizationAction, patientAutorizationSuccess, patientAutorizationFailure } = patientAutorizationSlice.actions;

export default patientAutorizationSlice.reducer;