const SepararString = (inputString?: string) => {
  // Verifica se a string começa com '+'
  inputString = inputString?.replace(/[()\s-]/g, "");
  if (inputString?.startsWith("+")) {
    const regex = /^\+(\d{1,4})(\d+)$/;
    const match = inputString?.match(regex);

    if (match) {
      const countryCode = match[1];

      if (["55", "34", "1"].includes(countryCode.slice(0, 2))) {
        // Separa a string removendo o código do país
        const restOfString = match[2];
        // Retorna os resultados formatados como uma única string
        return { countryCode, restOfString };
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const RemoveCountryCode = (inputString?: string) => {
  let phoneNumber = inputString?.replace(/[()\s-]/g, "");
  let countryCode = "";

  if (phoneNumber?.startsWith("+55")) {
    countryCode = "+55";
    phoneNumber = phoneNumber.slice(3);
  } else if (phoneNumber?.startsWith("+34")) {
    countryCode = "+34";
    phoneNumber = phoneNumber.slice(3);
  } else if (phoneNumber?.startsWith("+1")) {
    countryCode = "+1";
    phoneNumber = phoneNumber.slice(2);
  }

  if (phoneNumber?.startsWith("+")) {
    const index = phoneNumber.match(/[0-9]/)?.index;
    return { phoneNumber: phoneNumber.slice(index), countryCode };
  } else {
    return { phoneNumber, countryCode };
  }
};

export { SepararString, RemoveCountryCode };
